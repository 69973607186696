import React, {useEffect, useState} from 'react';
import {useSwipeable} from 'react-swipeable';

import {Add as AddIcon} from '@mui/icons-material';
import {
    Collapse,
    Fab,
    List,
    ListItem, ListItemButton,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';

import {useCanvasSave} from 'canvas/useCanvas';
import {
    ConfirmDialog, Drawer,
    MenuHeader, PremiumLock, Puller,
    Text, useConfirmDialog,
} from 'components';
import {translate, unTranslated, useTranslation} from 'hooks';
import {dateFormat} from 'lib/datefns';
import {useGroup} from 'module/group/useGroup';
import {SheetDialog,SheetIconButton} from 'module/sheet';
import {useSheet, useSheetCopy, useSheetDelete, useSheetSort} from 'module/sheet/useSheet';
import {useLicenseManager} from 'module/user';
import {useLayoutStore, usePrefsStore, useSnackbarStore} from 'store';

const styles = {
    actionButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '16px',
        borderBottom: '1px solid',
        borderBottomColor: 'grey.darkest2',
        cursor: 'pointer',
        background: 'linear-gradient(to bottom, #1c1c1c, #111)',
        position: 'relative',
    },
    actionButton: {
        zIndex: 9,
        flexShrink: 0,
        backgroundColor: 'grey.darker',
        fontSize: '30px',
        marginRight: '16px',
        ':hover': {
            backgroundColor: 'grey.darker',
        },
    },
    addText: {
        alignItems: 'center',
    },
    listHolder: {
        padding: 0,
    },
    listItem: {
        width: '100%',
        borderBottom: '1px solid',
        borderBottomColor: 'grey.darkest2',
    },
    listItemActive: {
        backgroundColor: 'grey.darkest',
        borderBottom: '1px solid',
        borderBottomColor: 'grey.darkest2',
    },
    listContent: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px !important',
    },
    itemTextCollapse: {
        display: 'flex',
        flexGrow: 1,
        overflow: 'hidden',
        '&. MuiCollapse-wrapperInner': {
            display: 'flex',
            flexGrow: 1,
        },
    },
};

const SheetItem = ({sheet, editSheet, isFirst, isLast, sheetCount, onToggle, onEdit, onDelete}) => {
    const {hasAccess} = useLicenseManager();

    const translate = useTranslation();
    const showInfo = useSnackbarStore((state) => state.show);

    const closeMenu = useLayoutStore(state => state.closeMenu);
    const setShowLoading = useLayoutStore(state => state.setShowLoading);

    const background = usePrefsStore(state => state.background);

    const {setSelected, selected} = useSheet();

    const {sort: sortSheet, loading: loading1} = useSheetSort();
    const {copy: copySheet, loading: loading2} = useSheetCopy();
    const {save: saveCanvas, loading: loading3} = useCanvasSave();

    const isActive = selected === sheet.id;

    const jumpToCanvas = (sheet) => {
        setSelected(sheet?.id);
        closeMenu();
    };

    const handleSort = (sheet, direction) => {
        sortSheet(sheet, direction);
    };

    const handleCopy = async (sheet) => {
        const newSheet = await copySheet(sheet);

        if (newSheet) {
            await saveCanvas(newSheet, {background});
            showInfo(translate('board.sheet.sheet') + ' ' + newSheet.name + ' ' + translate('global.created'));
        }
    };

    const handlers = useSwipeable({
        onSwiped: (e) => onToggle(e, sheet),
        trackMouse: true,
    });

    useEffect(() => {
        setShowLoading(loading1 || loading2 || loading3);
    }, [loading1, loading2, loading3, setShowLoading]);

    return <ListItem key={`sheet-${sheet.id}`} sx={[isActive ? styles.listItemActive : styles.listItem]}>
        <ListItemButton sx={styles.listContent} {...handlers}>
            <Collapse sx={styles.itemTextCollapse} in={sheet.id !== editSheet?.id} orientation="horizontal" collapsedSize={0} onClick={() => jumpToCanvas(sheet)}>
                <ListItemText
                    primary={sheet.name}
                    secondary={dateFormat(sheet.created, 'dd.MM.yyyy HH:mm')}/>
            </Collapse>
            <Puller onClick={(e) => onToggle(e, sheet)} />
            <Collapse in={sheet.id === editSheet?.id} orientation="horizontal" collapsedSize={0}>
                <Stack direction="row">
                    <SheetIconButton
                        icon="arrowup"
                        onClick={() => handleSort(sheet, 'up')}
                        disabled={isFirst}/>
                    <SheetIconButton
                        icon="arrowdown"
                        onClick={() => handleSort(sheet, 'down')}
                        disabled={isLast}/>
                    <SheetIconButton
                        icon="copy"
                        onClick={() => handleCopy(sheet)}/>
                    <SheetIconButton
                        icon="pencil"
                        onClick={() => onEdit(sheet)}
                    />
                    {hasAccess('group-sheets')?.limit?.min < sheetCount &&
                    <SheetIconButton
                        icon="delete"
                        onClick={() => onDelete(sheet)}
                        disabled={isActive}
                        color="red.dark"/>}
                </Stack>
            </Collapse>
        </ListItemButton>
    </ListItem>;

};

const Menu = () => {
    const showInfo = useSnackbarStore((state) => state.show);
    const {hasAccess} = useLicenseManager();

    const closeMenu = useLayoutStore(state => state.closeMenu);
    const showSheetMenu = useLayoutStore(state => state.showSheetMenu);
    const setShowSheetMenu = useLayoutStore(state => state.setShowSheetMenu);
    const setShowLoading = useLayoutStore(state => state.setShowLoading);

    const [editSheet, setEditSheet] = useState(null);
    const [showEdit, setShowEdit] = useState(false);

    const {selected: groupId, selectedGroup} = useGroup();
    const {sheets, selected} = useSheet(groupId);

    const {del: deleteSheet, loading: loading1} = useSheetDelete();

    const [dialogTitle, setDialogTitle] = useState('');

    const handleEdit = (sheet) => {
        setDialogTitle(translate('board.sheet.action.edit'));
        setEditSheet(sheet);
        setShowEdit(true);
    };

    const handleAdd = () => {
        setDialogTitle('board.sheet.action.create');
        setEditSheet(null);
        setShowEdit(true);
    };

    const handleDelete = (sheet) => {
        if (sheet.id !== selected) {
            deleteConfirm.show(sheet);
        } else {
            showInfo('board.sheet.validation.deletion_impossible', {severity: 'warning'});
        }
    };

    const deleteConfirm = useConfirmDialog(
        async (sheet) => {
            try {
                await deleteSheet(sheet);
                showInfo('board.sheet.action.delete_success');
            } catch (e) {
                showInfo(e.message, {severity: 'warning'});
            }
        },
        undefined,
        'board.sheet.action.confirm_delete'
    );

    const onSave = (_sheet) => {
        closeEdit();
        if (editSheet === null) {
            closeMenu();
        }
    };

    const closeEdit = () => {
        setShowEdit(false);
    };

    const closeSheetMenu = () => {
        setShowSheetMenu(false);
    };

    const toggleEditSheet = (e, sheet) => {
        if (e.dir === 'Left') {
            setEditSheet(sheet);
        } else if (e.dir === 'Right') {
            setEditSheet(null);
        } else {
            setEditSheet(editSheet === sheet ? null : sheet);
        }
    };

    useEffect(() => {
        setShowLoading(loading1);
    }, [loading1, setShowLoading]);

    return (
        <Drawer open={showSheetMenu} onClose={() => setShowSheetMenu(false)}>
            <ConfirmDialog {...deleteConfirm} />
            {showEdit && <SheetDialog open={showEdit} onClose={closeEdit} sheet={editSheet} onSave={onSave} title={dialogTitle} />}

            {/* Menu Header */}
            <MenuHeader
                text={<><Text>board.group.group</Text>: {selectedGroup?.name}</>}
                handleBackButton={closeSheetMenu}
                handleClose={closeMenu}
            />
            {hasAccess('group-sheets')?.limit?.max > sheets.length && <Stack sx={styles.actionButtonContainer} onClick={handleAdd} direction="row" alignItems="center">
                <Fab disableRipple sx={styles.actionButton}><AddIcon color="white" /></Fab>
                <Typography><Text>board.sheet.action.create</Text></Typography>
            </Stack>}

            {hasAccess('group-sheets')?.limit?.max <= sheets.length && <Stack sx={styles.actionButtonContainer} onClick={() => console.log('LINK TO PREMIUM PAGE')} direction="row" alignItems="center">
                <PremiumLock text={unTranslated('Mehr Folien mit PREMIUM')} />
                <Stack direction="row" sx={styles.addText}>
                    <Fab disableRipple sx={styles.actionButton}><AddIcon color="white" /></Fab>
                    <Typography><Text>board.sheet.action.create</Text></Typography>
                </Stack>
            </Stack>}

            <List sx={styles.listHolder}>
                {sheets.map((sheet, idx) => {
                    return <SheetItem 
                        key={sheet.id} 
                        sheet={sheet} 
                        sheetCount={sheets.length}
                        isFirst={idx === 0}
                        isLast={idx === sheets.length - 1}
                        editSheet={editSheet} 
                        onToggle={toggleEditSheet} 
                        onEdit={handleEdit} 
                        onDelete={handleDelete}/>;
                })}
            </List>
        </Drawer>
    );
};

export default Menu;