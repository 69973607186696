import React, {useEffect, useRef, useState} from 'react';

import {
    Add as AddIcon,
    Clear as CloseIcon,
    Pause as PauseIcon,
    PlayArrow as PlayIcon,
    Remove as RemoveIcon,
} from '@mui/icons-material';
import {Box, Slider, Stack, useMediaQuery, useTheme} from '@mui/material';

import {animFrame, animPlayFrame} from 'canvas/canvas-animation';
import {useCanvasLoad} from 'canvas/useCanvas';
import {useCanvasStore} from 'canvas/zustand';
import {PremiumLock} from 'components';
import {MAX_ANIMATION_FRAMES} from 'config';
import {unTranslated} from 'hooks';
import {sleepWithCancel} from 'lib/sleep';
import {sendImage} from 'module/lockerRoom/api';
import {useLockerRoomStore} from 'module/lockerRoom/zustand';
import {useLicenseManager} from 'module/user';
import {useSnackbarStore} from 'store';
import {useLayoutStore} from 'store';

import {discardActiveObject} from '../../canvas/canvas-helper.js';

import {useFrameAdd, useFrameClear, useFrameDelete} from './useFrame';
import {useSheet} from './useSheet';

const styles = {
    sliderContainerOverall: {
        width: '100%',
        height: {
            mobile: '100px',
            desktop: '120px',
        },
    },
    buttonPlaceholder: {
        width: {
            mobile: '12.5%',
            desktop: '7.692307%',
        },
    },
    buttonContainer: {
        background: '#191919',
        height: {
            mobile: '50px',
            desktop: '70px',
        },
        marginBottom: '23px',
    },
    button: {
        position: 'relative',
        cursor: 'pointer',
        width: {
            mobile: '12.5%',
            desktop: '7.692307%',
        },
        color: 'white.main',
        fontSize: {
            mobile: 20,
            desktop: 30,
        },
    },
    buttonIcon: {
        width: {
            mobile: 30,
            desktop: 40,
        },
        height:{
            mobile: 30,
            desktop: 40,
        },
    },
    buttonError: {
        color: 'red.dark',
        fontSize: {
            mobile: 30,
            desktop: 40,
        },
    },
    sliderContainer: {
        display: 'flex',
        alignItems: 'center',
        px: 3,
        py: 0,
    },
    slider: {
        padding: 0,
        '@media (pointer: coarse)': {
            padding: 0
        },
    },
    selected: {
        backgroundColor: 'black.light',
    },
};

const INITIAL_SPEED = 1000;
const DEFAULT_SPEED = INITIAL_SPEED;

// lower means fast
// its values [1000, 900, 800]
// 1000 -> 1 second between frames, 900 -> 900ms between frames, 800 -> 800ms between frames
const speedOptions = [
    {
        label: '1x',
        value: INITIAL_SPEED + 1000,
    },
    {
        label: '2x',
        value: INITIAL_SPEED + 500,
    },
    {
        label: '3x',
        value: INITIAL_SPEED,
    }
];

const Button = ({children, selected, onClick}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const size = isMobile ? '50px' : '70px';

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontSize={size}
            onClick={onClick}
            sx={[styles.button, selected && styles.selected]}
        >
            {children}
        </Box>
    );
};

const AnimationFrames = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const {hasAccess} = useLicenseManager();

    const [frame, setFrame] = useState({index: 0, duration: 500});
    const [playing, setPlaying] = useState(false);
    const [playSpeed, setPlaySpeed] = useState(DEFAULT_SPEED);

    const setShowTimeline = useLayoutStore((state) => state.setShowTimeline);

    const [abortController, setAbortController] = useState(new AbortController());

    const showInfo = useSnackbarStore((state) => state.show);
    const canvas = useCanvasStore((state) => state.canvas);
    const setDirty = useCanvasStore((state) => state.setDirty);

    const {load: loadCanvas} = useCanvasLoad();
    const {selected, selectedSheet: sheet, setFrameIndex, getById: getSheetById, getNumFrames} = useSheet();
    const numFrames = getNumFrames();
    const hasFrames = numFrames > 0;

    const {add: insertFrame} = useFrameAdd();
    const {del: removeFrame} = useFrameDelete();
    const {clear: clearFrames} = useFrameClear();

    const lockerroomEditor = useLockerRoomStore((state) => state.editor);
    const lockerroomActive = useLockerRoomStore((state) => state.active);

    const isLockerroomEditorRef = useRef();
    const isLockerroomActiveRef = useRef();

    const handleSpeed = (speed) => {
        setPlaySpeed(speed);
    };

    const addFrame = async () => {
        if (sheet?.frames?.length >= MAX_ANIMATION_FRAMES) {
            showInfo('board.sheet.validation.max_moves', {severity: 'warning', closeButton: false});
            return;
        }

        showInfo(unTranslated('Neuer Frame'), {severity: 'success', closeButton: false});

        const newIndex = frame.index + 1;
        console.log('addFrame', newIndex);

        try {
            const newFrame = await insertFrame(sheet, animFrame(canvas), newIndex);
            if (!newFrame) {
                return;
            }

            setFrame({index: newIndex, duration: 100});
            setDirty(false);
        } catch (e) {
            showInfo(e.message, {severity: 'warning', closeButton: false});
        }
    };

    const cutFrame = async () => {
        // do not delete first key frame, if there are other frames available
        if (frame.index === 0 && numFrames > 1) {
            showInfo(unTranslated('Der erste Frame kann erst gelöscht werden, wenn es keine Weiteren gibt.'), {severity: 'error', closeButton: false});
            return;
        }

        console.log('removeFrame', frame.index, numFrames);

        try {
            await removeFrame(sheet, frame.index);
            // showInfo(unTranslated('Frame gelöscht.'), {severity: 'success', closeButton: false});
        } catch (e) {
            console.log(e.cause);
            showInfo(e.message, {severity: 'warning', closeButton: false});
            return;
        }

        if (numFrames === 1) {
            await close();
            return;
        }

        let jumpTo = frame.index >= numFrames - 1 ? frame.index - 1 : frame.index;

        if (jumpTo < 0) {
            jumpTo = 0;
        }

        setFrame({...frame, index: jumpTo});
        setDirty(false);
    };

    const play = async () => {
        // remove selection otherwise selection will be animated too
        discardActiveObject(canvas);

        setPlaying(true);

        const wait = playSpeed;

        const start = frame.index >= numFrames - 1 ? 0 : frame.index;

        for (let idx = start; idx <= numFrames - 1; idx++) {
            setFrame({duration: wait, index: idx});

            try {
                const abortCtrl = new AbortController();
                setAbortController(abortCtrl);

                for (let w = 1; w <= 20; w++) {
                    await sleepWithCancel(wait / 20 * 2, abortCtrl.signal);
                    // lockerroom
                    sendImage(canvas, isLockerroomEditorRef.current, isLockerroomActiveRef.current);
                }
            } catch (e) {
                break;
            }
        }

        setPlaying(false);
    };

    const pause = () => {
        abortController.abort();
    };

    const goto = (idx) => {
        // remove selection otherwise selection will be animated too
        discardActiveObject(canvas);

        if (!hasFrames || idx === frame.index) {
            return;
        }

        console.log(`goto ${frame.index} -> ${idx}`);

        setFrame({index: idx, duration: 100});
    };

    const close = async () => {
        // if there is only the key-frame, remove frames at all
        if (numFrames === 1) {
            await clearFrames(sheet);
        }

        await loadCanvas(sheet);
        setShowTimeline(false);
    };

    const playButtonHandler = playing ? pause : play;
    const PlayButtonIcon = playing ? PauseIcon : PlayIcon;

    useEffect(() => {
        isLockerroomEditorRef.current = lockerroomEditor;
        isLockerroomActiveRef.current = lockerroomActive;
    }, [lockerroomActive, lockerroomEditor]);

    useEffect(() => {
        const sht = getSheetById(selected);

        if (!sht) {
            return;
        }

        const numFrames = sht.frames.length;

        console.log('Anims', sht, numFrames);

        // insert key frame
        if (numFrames === 0 && sht.type) {
            console.log('Insert key frame');
            insertFrame(sht, animFrame(canvas), 0);
        }

        setFrame({...frame, index: 0});

        // must run if selected sheet changes only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, insertFrame, canvas]);

    useEffect(() => {
        setFrameIndex(frame.index);

        if (canvas) {
            animPlayFrame(canvas,
                sheet?.frames,
                frame.index,
                frame.duration,
                () => sendImage(canvas, isLockerroomEditorRef.current, isLockerroomActiveRef.current)
            );
        }
    }, [frame, setFrameIndex, sheet, canvas]);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    useEffect(() => {
        return () => {
            setFrameIndex(0);
        };
    }, [setFrameIndex]);

    return <Stack sx={styles.sliderContainerOverall}>

        <Stack direction="row" sx={styles.buttonContainer}>

            {hasFrames &&
                <Button
                    disabled={!sheet?.frames}
                    onClick={playButtonHandler}>
                    <PlayButtonIcon sx={styles.buttonIcon}/>
                </Button>
            }

            {speedOptions.map((option, index) => (
                <Button
                    key={`speedIndex-${index}`}
                    onClick={() => handleSpeed(option.value)}
                    selected={option.value === playSpeed}>
                    {option.label}
                </Button>
            ))}

            <Box sx={styles.buttonPlaceholder}/>

            {!isMobile &&
            <>
                <Box sx={styles.buttonPlaceholder}/>
                <Box sx={styles.buttonPlaceholder}/>
                <Box sx={styles.buttonPlaceholder}/>
                <Box sx={styles.buttonPlaceholder}/>
                <Box sx={styles.buttonPlaceholder}/>
            </>
            }

            {hasFrames &&
                <Button onClick={cutFrame} disabled={playing || !hasFrames}>
                    {/* <RemoveIcon sx={!playing && hasFrames ? styles.active : styles.inActive}/> */}
                    <RemoveIcon sx={styles.buttonIcon}/>
                </Button>
            }

            {hasAccess('group-moves')?.limit?.max > numFrames ?
                <Button onClick={addFrame} disabled={playing}>
                    {/* <AddIcon sx={playing ? styles.inActive : styles.active}/> */}
                    <AddIcon sx={styles.buttonIcon} />
                </Button> :
                <Button onClick={() => console.log('LINK TO PREMIUM PAGE')}>
                    <PremiumLock />
                    <AddIcon sx={styles.buttonIcon} />
                </Button>
            }

            <Button onClick={close}>
                <CloseIcon sx={styles.buttonError}/>
            </Button>

        </Stack>

        <Box sx={styles.sliderContainer} zIndex={13}>
            <Slider
                size={isMobile ? 'small' : undefined}
                sx={styles.slider}
                color="secondary"
                step={1}
                onChange={(_e, v) => goto(v)}
                value={frame.index}
                valueLabelFormat={value => value + 1}
                marks={true}
                min={0}
                max={hasFrames ? numFrames - 1 : 1}
                valueLabelDisplay="on"
            />
        </Box>
    </Stack>;
};

export default AnimationFrames;
