import React from 'react';

import {Lock as LockIcon} from '@mui/icons-material';
import {Stack, Typography} from '@mui/material';

import {Text} from 'components/index';
import {useUserUtils} from 'module/user/useUser';
import {useLayoutStore} from 'store';

const styles = {
    lockContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        padding: 2,
        boxSizing: 'border-box',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        backdropFilter: 'blur(5px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 0,
        zIndex: 10,
        textAlign: 'center',
    },
    lockIcon: {
        color: 'white.main',
        width: '34px',
        height: '34px',
        marginBottom: '6px',
    },
};

const PremiumLock = ({text = ''}) => {
    
    const {isAuthenticated} = useUserUtils();
    const setShowLogin = useLayoutStore((state) => state.setShowLogin);

    if (!isAuthenticated) {
        return <Stack sx={styles.lockContainer} onClick={() => setShowLogin(true)}>
            <Stack><LockIcon sx={styles.lockIcon} /></Stack>
            {text !== '' && <Typography fontSize={13}><Text>account.page.login</Text></Typography> }
        </Stack>;
    }

    return <Stack sx={styles.lockContainer}>
        <Stack><LockIcon sx={styles.lockIcon} /></Stack>
        {text && <Typography fontSize={13}><Text>{text}</Text></Typography> }
    </Stack>;
};

export default PremiumLock;
