import {create} from 'zustand';

import {middleware} from 'store/zustand';

import {produce} from 'immer';

export const usePairingStore = create(middleware((set, _get) => ({
    activePairing: {},
    set: p => set(produce(draft => {
        draft.activePairing = p;
    })),
    unset: () => set(produce(draft => {
        draft.activePairing = {};
    })),
}), {name: 'PairingStore', debug: true}));
