import {useCallback} from 'react';

import {isPremiumDevelopment} from 'lib/env';

import MODULE_OPTIONS_ESSENTIALS from './module_options_essentials.json';
import {useUserStore} from './zustand';

export const hasAccess = ((modules, module, option) => {
    if (isPremiumDevelopment()) {
        return {access: true, limit: null};
    }

    if (modules) {
        if (Object.prototype.hasOwnProperty.call(modules , module)) {
            let foundModule = modules[module];
            if (foundModule.options) {
                // module with options but specific option missing => no access
                let options = foundModule.options;
 
                if (options.length !== 0 && !options.includes(option)) {
                    // option missing => no access
                    // console.debug('useLicenseManager', module, option, false);

                    return {access: false, limit: foundModule.limit};
                } else {
                    // 1. if empty array => full access (no restrictions)
                    // console.debug('useLicenseManager', module, option, true);

                    // 2. all options or correct option => has access
                    return {access: true, limit: foundModule.limit};
    
                }
            } else {
                // module but no options => has access
                // console.debug('useLicenseManager', module, option, true);

                return {access: true, limit: foundModule.limit};
            }
        } else {
            // not found -> no access
            // console.debug('useLicenseManager', module, option, false);

            return {access: false, limit: 0};

        }
    } else {
        // empty modules => no access
        console.error('useLicenseManager NO MODULES', module, option, false);
        return {access: false, limit: null};
    }
});

//  helper function to display premium upgrade messages (only working for modules with options)
export const hasFullAccess = ((modules, module) => {
    if (isPremiumDevelopment()) {
        return true;
    }

    // not logged or backend problems -> no access
    if (!modules) 
        return false; 
    
    if (Object.prototype.hasOwnProperty.call(modules , module)) {
        let foundModule = modules[module];
        let options = foundModule.options;
        // all options available -> full access
        // no options => full access
        return (foundModule && !options) || options.length === 0; 
            
    } else {
        // module not found -> no access
        return false;
    }
   
});

export const hasAccessFunction = ((module, option) => { 
    const modules = useUserStore.getState().user?.modules || MODULE_OPTIONS_ESSENTIALS;
    return hasAccess(modules, module, option);

});

// checks if user is owner of a specific module, otherwise returns false
export const useLicenseManager = () => {
    // if no modules/no user -> use board essentials modules
    const modules = useUserStore((state) => state?.user?.modules) || MODULE_OPTIONS_ESSENTIALS;
    const hasAccessCB = useCallback((module, option) => {
        return hasAccess(modules, module, option);
    }, [modules]);
    const hasFullAccessCB = useCallback((module) => {
        return hasFullAccess(modules, module);
    }, [modules]);
    return {hasAccess: hasAccessCB, hasFullAccess: hasFullAccessCB};
};
