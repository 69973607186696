import React from 'react';

import {Lock as LockIcon} from '@mui/icons-material';
import {Stack, Typography} from '@mui/material';

import {Text} from 'components';
import {unTranslated} from 'hooks';
import {useUserUtils} from 'module/user/useUser';
import {useLayoutStore} from 'store';

const styles = {
    premiumInfo: {
        padding: 2,
        boxSizing: 'border-box',
        backgroundColor: 'red.highlight',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        borderRadius: 0,
        zIndex: 10,
        textAlign: 'center',
        marginTop: '32px',
        cursor: 'pointer',
    },
    premiumInfoIcon: {
        color: 'white.main',
        width: '16px',
        height: '16px',
        marginRight: '6px',
    },
};

const AboInfo = () => {
    const {isAuthenticated} = useUserUtils();
    const setShowLogin = useLayoutStore((state) => state.setShowLogin);

    if (!isAuthenticated) {
        return <Stack sx={styles.premiumInfo} onClick={() => setShowLogin(true)}>
            <Stack><LockIcon sx={styles.premiumInfoIcon} /></Stack>
            <Typography fontSize={14}><Text>rest.global.unauthorized</Text></Typography>
        </Stack>;
    }

    return <Stack sx={styles.premiumInfo} onClick={() => console.log('LINK TO PREMIUM PAGE')}>
        <Stack><LockIcon sx={styles.premiumInfoIcon} /></Stack>
        <Typography fontSize={14}><Text>{unTranslated('Freischalten mit PREMIUM')}</Text></Typography>
    </Stack>;
};

export default AboInfo;