import {useCallback, useEffect} from 'react';

import {Modes} from 'lib/tactix';
import {useLockerRoomStore} from 'module/lockerRoom/zustand';
import {useSettingsStore} from 'module/settings/zustand';
// import {useSnackbarStore} from 'store';
import {useInfoStore} from 'store/info';

import {setupBrowserUserData} from './api';
import {useUserStore} from './zustand';

export const useUser = () => {
    // const showInfo = useSnackbarStore((state) => state.show);

    const setUser = useUserStore((state) => state.setUser);
    const user = useUserStore((state) => state.user);
    // const setRoomId = useLockerRoomStore((state) => state.setRoomId);
    // const roomId = useLockerRoomStore((state) => state.roomId);
    const active = useLockerRoomStore((state) => state.active);
    const opening = useLockerRoomStore((state) => state.opening);
    // const setOpening = useLockerRoomStore((state) => state.setOpening);
    const mode = useInfoStore(state => state.mode);

    const setSelectedTeam = useSettingsStore((store) => store.setSelectedTeam);
    // const setSelectedClient = useSettingsStore((store) => store.setSelectedClient);

    // const userRef = useRef(user);
    // const activeRef = useRef(active);
    // const openingRef = useRef(opening);

    useEffect(() => {
    
        // WARNING: NEW EVENT BASED USER EVENTS ON APP VERSION >= 6.0.0
        // connected to mobile device or electron
        if (mode === Modes.APP) {
            // params = await setupAppUserData(openingRef.current, activeRef.current, roomId, setRoomId, setSelectedTeam, userRef.current, setUser);
      
        } // end mode app

        if (mode === Modes.BROWSER) {
            // browser mode
            // console.log('LOCKEROOM: using Browser URL params');
            // development only                    
            setupBrowserUserData(opening, active, setSelectedTeam, user, setUser);

        } // end browser mode

        if (mode === Modes.MONITOR) {
            // Monitor mode
            // browser mode gets no user - so is using params
        } // end Monitor mode
        
        return () => {
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
    // return useCallback(async () => {
    //     let params = null;

    //     // console.log('useUser callback roomid: ' + roomId + ' user: ' + JSON.stringify(user?.firstname) +' mode: ' + mode);

    //     // WARNING: NEW EVENT BASED USER EVENTS ON APP VERSION >= 6.0.0
    //     // connected to mobile device or electron
    //     if (mode === Modes.APP) {
    //         params = await setupAppUserData(openingRef.current, activeRef.current, roomId, setRoomId, setSelectedTeam, userRef.current, setUser);
    //     } // end mode app

    //     if (mode === Modes.BROWSER) {
    //         // browser mode
    //         // console.log('LOCKEROOM: using Browser URL params');
    //         // development only                    
    //         await setupBrowserUserData(opening, active, setSelectedTeam, user, setUser);

    //         return userRef.current;
    //     } // end browser mode

    //     if (mode === Modes.MONITOR) {
    //         // Monitor mode
    //         // TODO get user in pairing mode
    //         // browser mode gets no user - so is using params
    //         return userRef.current;
    //     } // end Monitor mode

    //     if (params && params.error) {
    //         console.log('failed', params.error);
    //         showInfo(params.error.message, {severity: 'error'});
    //         return null;
    //     }

    //     return params;
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [roomId, mode]);
};

export const useUserUtils = () => {
    const setUser = useUserStore((state) => state.setUser);
    const user = useUserStore((state) => state.user);

    const logout = useCallback(() => {
        setUser(null);
    }, [setUser]);

    const isOwner = useCallback((haystack) => {
        if (Array.isArray(haystack)) {
            return haystack.includes(user.id);
        } else {
            return haystack === user?.id;
        }
    }, [user]);
    const isAuthenticated = !!user?.jwt;

    return {logout, isAuthenticated, isOwner};
};