import {create} from 'zustand';

import {middleware} from 'store/zustand';

import {produce} from 'immer';

export const useLayoutStore = create(middleware((set, _get) => ({
    showPairing: false,
    showFieldSelector: false,
    showLoading: false,
    showLogin: false,
    showHelp: false,
    showLanguage: false,
    showTimeline: false,
    showDrawer: false,
    showSettings: false,
    showInformationDialog: false,
    showGroupMenu: {
        show: false,
        parent: null,
    },
    showSheetMenu: false,
    showClientMenu: false,
    showClient: {
        show: false,
        client: null,
    },
    showTeam: {
        show: false,
        team: null,
    },
    showMember: {
        show: false,
        member: null,
    },
    showProfile: false,
    showLineup: false,
    lineupIsDragging: false,
    setShowPairing: (val) => set(produce((draft) => {
        if (val) {
            closeMenu(draft);
        }

        draft.showPairing = val;
    })),
    setShowFieldSelector: (val) => set(produce((draft) => {
        draft.showFieldSelector = val;
    })),
    setShowLoading: (val) => set(produce((draft) => {
        draft.showLoading = val;
    })),
    setShowLogin: (val) => set(produce((draft) => {
        draft.showLogin = val;
    })),
    setShowHelp: (val) => set(produce((draft) => {
        draft.showHelp = val;
    })),
    setShowLanguage: (val) => set(produce((draft) => {
        draft.showLanguage = val;
        draft.showDrawer = !val;
    })),
    setShowTimeline: (val) => set(produce((draft) => {
        draft.showTimeline = val;
    })),
    setShowDrawer: (val) => set(produce((draft) => {
        draft.showDrawer = val;
        if (val) {
            draft.showGroupMenu.show = !val;
            draft.showSettings = !val;
        }
    })),
    setShowSettings: (val) => set(produce((draft) => {
        draft.showSettings = val;
        draft.showDrawer = !val;
    })),
    setShowInformationDialog: (val) => set(produce((draft) => {
        draft.showInformationDialog = val;
        draft.showDrawer = !val;
    })),
    setShowGroupMenu: (val, parent = null) => set( produce((draft) => {
        draft.showGroupMenu.show = val;
        if (draft.parent === 'drawer') {
            draft.showDrawer = !val;
        }

        draft.showGroupMenu.parent = val ? parent : null;
    })),
    setShowSheetMenu: (val) => set(produce((draft) => {
        draft.showSheetMenu = val;
        draft.showGroupMenu.show = !val;
    })),
    setShowClientMenu: (val) => set(produce((draft) => {
        draft.showClientMenu = val;
        draft.showDrawer = !val;
    })),
    setShowClient: (val, client = null) => set(produce((draft) => {
        draft.showClient.show = val;
        draft.showClient.client = client;
        draft.showClientMenu = !val;
    })),
    setShowTeam: (val, team = null) => set(produce((draft) => {
        draft.showTeam.show = val;
        draft.showTeam.team = team;
        draft.showClient.show = !val;
    })),
    setShowMember: (val, member = null) => set(produce((draft) => {
        draft.showMember.show = val;
        draft.showMember.member = member;
        draft.showTeam.show = !val;
    })),
    setShowLineup: (val) => set(produce((draft) => {
        draft.showLineup = val;
    })),
    setLineupIsDragging: (val) => set(produce((draft) => {
        draft.lineupIsDragging = val;
    })),
    setShowProfile: (val) => set(produce((draft) => {
        draft.showProfile = val;
    })),
    closeMenu: () => set(produce((draft) => {
        closeMenu(draft);
    }))
}), {name: 'LayoutStore', debug: false}));

const closeMenu = (draft) => {
    draft.showGroupMenu = {
        show: false,
        parent: null
    };
    draft.showSheetMenu = false;
    draft.showDrawer = false;
    draft.showSettings = false;
    draft.showClientMenu = false;
    draft.showClient = {
        show: false,
        client: null,
    };
    draft.showClientEdit = false;
    draft.showTeam = {
        show: false,
        team: null,
    };
    draft.showMember = {
        show: false,
        member: null,
    };
    draft.showLineup = false;
    draft.showProfile = false;
    draft.showInformationDialog = false;
    draft.showLanguage = false;
};
