import {useEffect,useState} from 'react';

import {useInterval} from 'hooks';
import {isDemo} from 'lib/env';
import {useLoadActiveLockerrooms,} from 'module/lockerRoom/useLoadActiveLockerrooms';
import {useLoadActiveUsersInLockerroom} from 'module/lockerRoom/useLoadActiveUsersInLockerroom';
import {useLockerRoomStore} from 'module/lockerRoom/zustand';
import {usePairingLoad, usePairingStore} from 'module/pairing';
import {useUserStore} from 'module/user';
import {useUserUtils} from 'module/user/useUser';
import {useApiGet} from 'module/user/useUserApi';

import demoCampaign from './assets/demodata/campaign.json';
import demoInfo from './assets/demodata/info.json';
import {loadCampaign} from './store/campaign';
import {loadInfo} from './store/info';
import {useCampaignStore, useInfoStore} from './store';

const Periodic = () => {
    const [timer, setTimer] = useState(0);
    const setInfo = useInfoStore(state => state.setInfo);
    const setCampaign = useCampaignStore(state => state.setCampaign);
    const isLockerroomActive = useLockerRoomStore((state) => state.active);
    const activePairing = usePairingStore((state) => state.activePairing);
    const setActivePairing = usePairingStore((state) => state.set);
    const unsetActivePairing = usePairingStore((state) => state.unset);
    const {pairing} = usePairingLoad();
    const {logout} = useUserUtils();
    
    const setUser = useUserStore(state => state.setUser);
    const query = useApiGet();
  
    const loadActiveLockerrooms = useLoadActiveLockerrooms();
    const loadActiveUsersInLockerroom = useLoadActiveUsersInLockerroom();

    const load = async () => {
        // gives the lockerroom start button a flickering but prevents user from clicking when loading
        // setIsLoadingLockerrooms(true);
        const info = await loadInfo();
        setInfo(isDemo() ? demoInfo : info);

        const campaign = await loadCampaign();
        setCampaign(isDemo() ? demoCampaign : campaign);

        if (!isLockerroomActive) {
            await loadActiveLockerrooms();
        } else {
            await loadActiveUsersInLockerroom();
        }
    };

    const loadPairing = async () => {
        // 1. refreshes pairing data every second to get the user token as soon as the user entered the code in the app
        // 2. load pairing data every 4 minutes to update the user token
        if ((activePairing?.id && !activePairing?.jwt) || (activePairing?.id && timer > 240)) {
            console.debug('Periodic load pairing data',timer, timer > 240, activePairing);
            setTimer(0);
            try {
                let newPairing = await pairing(activePairing.id);
                console.debug('update pairing jwt', newPairing);
                if (newPairing.jwt && newPairing.jwt !== activePairing.jwt) {
                    // logout existing user
                    logout();
                    setActivePairing({...activePairing, jwt: newPairing.jwt, valid_until: newPairing.valid_until});
                    // set user jwt first to set the headers
                    setUser({jwt: newPairing.jwt});
                    let newUser = await query.refetch();
                    // const userData = query.data;
                    console.debug('update user userdata', newUser.data);
                    setUser({jwt: newPairing.jwt, ...newUser.data});
                }

            } catch (error) {
                // no active pairing 
                // TODO error handling for pairing data
                // console.error('Error getting pairing data', error);
            }

            // reset jwt timer

        } else {
            if (activePairing.jwt) {
                // check if app pairing still active every 5 seconds
                if (timer % 5 === 0) {
                    console.debug('Periodic timer pairing check ', timer);
                    try {
                        let newPairing = await pairing(activePairing.id);
                        console.log('update pairing jwt check', newPairing);

                    } catch (error) {
                        // no active pairing 
                        console.log('Pairing inactive, logout user');
                        // logout existing user
                        unsetActivePairing();
                        logout();
                        // console.error('Error getting pairing data', error);
                    }
                }
                
            }

            setTimer(timer + 1);

        }
        
    };

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useInterval(async () => {
        load();
    }, 5 * 1000);

    useInterval(async () => {
        loadPairing();
    }, 1000);

    return null;
};

export default Periodic;