import React, {useEffect} from 'react';

import {Box, Link} from '@mui/material';

import {Drawer} from 'components';
import {useTranslation} from 'hooks';
import {Modes} from 'lib/tactix';
import {useGetVersion} from 'module/version';

import appleAppStore from '/src/assets/apple-app-store.png';
import googlePlayStore from '/src/assets/google-play-badge.png';

const styles = {
    dialogContentBlockTitle: {
        color: 'white.main',
        padding: '40px 40px 5px 40px',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '24px',
    },
    dialogContentBlock: {
        color: 'white.main',
        padding: '0 40px 20px 40px',
        textAlign: 'center',
    },
    dialogContentBlockButton: {
        color: 'white.main',
        padding: '0 0 10px 0',
        textAlign: 'center',
    },
    dialogContentBlockButtonHighlight: {
        color: 'white.main',
        padding: '0 0 10px 0',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '18px',
    },
    buttonHolder: {
        padding: '20px 40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textDecoration: 'none',
    },
};

const navigateToStore = (storeUrl) => {
    const eventData = {url: storeUrl};
    window.ReactNativeWebView.postMessage(JSON.stringify({type: 'navigateToStoreEvent', data: eventData}));
};

const VersionCheck = ({mode}) => {
    const translate = useTranslation();

    let params = new URLSearchParams(window.location.search);
    const paramVersion = params.get('appVersion');

    const {getVersion, versionData, loading, hasError} = useGetVersion();

    useEffect(() => {
        if (mode === Modes.APP) {
            getVersion('app');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (hasError) {
        console.error('Error fetching version data:', hasError);
    }

    const versionString = `${versionData?.major}.${versionData?.minor}.${versionData?.build}`;
    console.debug('versionString', paramVersion, versionString, paramVersion !== versionString);
    if (loading || !paramVersion || !versionData || paramVersion === versionString) {
        return null;
    }

    return <Drawer open handleClose={() => null}>
        <Box sx={styles.dialogContentBlockTitle}>{translate('version.message.old_version')}</Box>

        <Box sx={styles.dialogContentBlock}>{translate('version.message.update')}</Box>

        <Link
            onClick={() => navigateToStore('https://apps.apple.com/at/app/geomix/id1572802456')}
            sx={styles.buttonHolder}>
            <Box sx={styles.dialogContentBlockButton}>{translate('version.update.apple')}<Box
                sx={styles.dialogContentBlockButtonHighlight}>{translate('version.here')}:</Box></Box>
            <img src={appleAppStore} alt="" width="300"/>
        </Link>

        <Link
            onClick={() => navigateToStore('https://play.google.com/store/apps/details?id=com.geomix.tactix')}
            sx={styles.buttonHolder}>
            <Box sx={styles.dialogContentBlockButton}>{translate('version.update.android')}</Box>
            <Box sx={styles.dialogContentBlockButtonHighlight}>{translate('version.here')}:</Box>
            <img src={googlePlayStore} alt="" width="300"/>
        </Link>

    </Drawer>;
};

export default VersionCheck;