import React, {useEffect, useState} from 'react';

import {Add as AddIcon} from '@mui/icons-material';
import {
    Fab,
    List,
    Stack,
    Typography
} from '@mui/material';

import {
    ConfirmDialog,
    Text, useConfirmDialog,
} from 'components';
import {useTranslation} from 'hooks';
import {IconButton} from 'module/client/index';
import {MenuItem} from 'module/client/index';
import {Create, Edit} from 'module/client/team';
import {useApiDelete, useApiQuery} from 'module/client/team/useTeamApi';
import {useLicenseManager} from 'module/user';
import {useUserUtils} from 'module/user/useUser';
import {useLayoutStore, useSnackbarStore} from 'store';

const styles = {
    container: {
        paddingX: 2.5,
        paddingBottom: 2.5,
    },
    actionButtonContainer: {
        paddingY: 2.5,
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'grey.darkest2',
        cursor: 'pointer',
    },
    actionButton: {
        flexShrink: 0,
        backgroundColor: 'red.highlight',
        marginRight: 2.5,
        ':hover': {
            backgroundColor: 'red.dark',
        },
    },
    listHolder: {
        padding: 0,
    },
};

const Team = ({team, onClick, onToggleMenu, showMenu, onDelete, onEdit}) => {
    const translate = useTranslation();

    const {isOwner} = useUserUtils();

    const collapseButtons = [];

    if (isOwner(team.managers)) {
        collapseButtons.push(<IconButton
            key="pencil"
            icon="pencil"
            onClick={() => onEdit(team)}
        />);

        collapseButtons.push(<IconButton
            key="delete"
            icon="delete"
            color="red.dark"
            onClick={() => onDelete(team)}
        />);
    }

    return <MenuItem
        onClick={() => onClick(team)}
        avatarText={team.name}
        image={team.image}
        listItemTextPrimary={team.name}
        listItemTextSecondary={`${team.members.length} ${translate('client.members')}`}
        pullerFunction={(e) => onToggleMenu(e, team)}
        collapseButtons={collapseButtons}
        showMenu={showMenu}
    />;
};

const Teams = ({client, _onClick}) => {
    const translate = useTranslation();
    const [selection, setSelection] = useState(null);
    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const {hasAccess} = useLicenseManager();

    const {isOwner} = useUserUtils();

    const setShowTeam = useLayoutStore(state => state.setShowTeam);
    const setShowLoading = useLayoutStore((state) => state.setShowLoading);

    const showInfo = useSnackbarStore((state) => state.show);

    const {del, loading} = useApiDelete();

    const query = useApiQuery({clientId: client.id});

    const onSave = async (newTeam) => {
        setShowCreate(false);
        await query.refetch();
        setSelection(newTeam);
    };

    const handleDelete = (team) => {
        deleteConfirm.show(team);
    };

    const deleteConfirm = useConfirmDialog(
        async (team) => {
            try {
                del(team.id);
                query.refetch();
                showInfo(translate('team.action.delete_success'));
            } catch (e) {
                showInfo(e.message, {severity: 'warning'});
            }
        },
        undefined,
        translate('team.action.confirm_delete')
    );

    const handleEdit = (_id) => {
        setShowEdit(true);
    };

    const handleSelect = (team) => {
        setShowTeam(true, team);
    };

    const toggleMenu = (e, team) => {
        if (e.dir === 'Left') {
            setSelection(team);
        } else if (e.dir === 'Right') {
            setSelection(null);
        } else {
            setSelection(selection === team ? null : team);
        }
    };

    useEffect(() => {
        setShowLoading(query.isFetching || loading);
        return () => {
            setShowLoading(false);
        };
    }, [query.isFetching, setShowLoading, loading]);
    console.log('clientteams', query.data?.length, 'hasAccess', hasAccess('client-teams', 'limit')?.limit?.max);
    return (
        <>
            <ConfirmDialog {...deleteConfirm} />
            {showCreate && <Create client={client} onSave={onSave} onClose={() => setShowCreate(false)}/>}
            {showEdit && <Edit team={selection} onSave={onSave} onClose={() => setShowEdit(false)}/>}

            <Stack sx={styles.container}>

                {isOwner(client.user_id) && hasAccess('client-teams')?.limit?.max > query.data?.length && <Stack
                    sx={styles.actionButtonContainer} onClick={() => setShowCreate(true)} direction="row" alignItems="center">
                    <Fab disableRipple sx={styles.actionButton}><AddIcon color="white"/></Fab>
                    <Typography><Text>team.action.create2</Text></Typography>
                </Stack>}

                {/* // TODO styling / translation  */}
                {hasAccess('client-teams')?.limit?.max <= query.data?.length && <Stack
                    sx={styles.actionButtonContainer} onClick={() => console.log('LINK TO PREMIUM PAGE')} direction="row" alignItems="center">
                    <Fab disableRipple sx={styles.actionButton}><AddIcon color="white"/></Fab>
                    <Typography><Text>{/* unTranslated */}Mehr Teams mit Premium!</Text></Typography>
                </Stack>}

                <List sx={styles.listHolder}>
                    {query.data?.map(team => <Team
                        key={team.id}
                        team={team}
                        onToggleMenu={toggleMenu}
                        onClick={handleSelect}
                        showMenu={selection?.id === team.id}
                        onDelete={handleDelete}
                        onEdit={handleEdit}
                    />)}
                </List>
            </Stack>
        </>
    );
};

export default Teams;