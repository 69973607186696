import React, {forwardRef, useEffect} from 'react';

import {ButtonBase, MenuItem, Slide, Stack} from '@mui/material';

import {yupResolver} from '@hookform/resolvers/yup';

import {Dialog, Text} from 'components';
import {useTranslation} from 'hooks';
import {positions, roles} from 'module/client/team/useTeamApi';
import {useLayoutStore, useSnackbarStore} from 'store';

import {schema, useApiAdd} from './useMemberApi.js';

import {AutocompleteField, Form, FormField, SelectField, useForm} from 'form';

const styles = {
    button: {
        color: 'white.main',
        marginLeft: 4,
        fontSize: '16px'
    }
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const CreateDialog = ({team, onClose, onSave}) => {
    const translate = useTranslation();
    const setShowLoading = useLayoutStore((state) => state.setShowLoading);
    const showInfo = useSnackbarStore((state) => state.show);

    const {add, loading, data, errors} = useApiAdd();

    const doCancel = () => {
        onClose && onClose();
    };

    const doSave = async (formData) => {
        try {
            const res = await add(team.id, formData);
            onSave && onSave(res);
        } catch (e) {
            showInfo(e.message, {severity: 'warning'});
        }
    };

    useEffect(() => {
        setShowLoading(loading);
        return () => {
            setShowLoading(false);
        };
    }, [loading, setShowLoading]);

    const rhf = useForm(data, errors, true, {resolver: yupResolver(schema)});

    return <Dialog
        maxWidth={false}
        pt={0}
        open={true}
        TransitionComponent={Transition}
        handleClose={onClose}
        title="client.action.member.add"
    >
        <Form methods={rhf.methods} onSubmit={doSave}>
            <Stack direction="column" p={2}>
                <AutocompleteField label="client.role.roles" name="roles" multiple options={roles} disableClearable/>
                <FormField name="number" type="number" label="team.player.back_number" required/>
                <SelectField label="team.position.position" name="position">
                    {positions.map(position => <MenuItem key={position} value={position}>{translate(`team.position.${position}`)}</MenuItem>)}
                </SelectField>

                <Stack direction="row" justifyContent="flex-end" pb={1} pt={2}>
                    <ButtonBase disableRipple sx={styles.button} onClick={doCancel}>
                        <Text>ui.actions.cancel</Text>
                    </ButtonBase>
                    <ButtonBase disableRipple sx={styles.button} type="submit">
                        <Text>ui.actions.save</Text>
                    </ButtonBase>
                </Stack>
            </Stack>
        </Form>
    </Dialog>;
};

export default CreateDialog;