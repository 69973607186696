import React, {useEffect, useState} from 'react';
import {ErrorBoundary} from 'react-error-boundary';

import '@mui/material/styles/styled'; // @see https://github.com/mui/material-ui/issues/36511

import {Box, CssBaseline} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

import {Snackbar} from 'components';
import {isDevelopment, isProduction} from 'lib/env';
import {getMode} from 'lib/tactix';
import {Modes} from 'lib/tactix';
import {defaultLang,useSettingsStore} from 'module/settings/zustand';
import {useUserStore} from 'module/user';
import {useInfoStore} from 'store';
import {useLayoutStore} from 'store';

import {version} from '../package.json';

//import loadDemoData from './assets/demodata';
import Text from './components/Text';
import {Bootstrap} from './Bootstrap';
import {Layout} from './layout';
import Periodic from './Periodic';
import VersionCheck from './VersionCheck';

import './App.css';
import '@fontsource/inter/100.css';
import '@fontsource/inter/200.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';

import FontFaceObserver from 'fontfaceobserver';
import LogRocket from 'logrocket';
import {Settings} from 'luxon';
import theme from 'theme/Theme';

const styles = {
    loadingInfoContainer: {
        backgroundColor: '#000',
        padding: 0,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        fontSize: 22,
        fontWeight: 400,
        color: '#fff',
        textAlign: 'center',
    }
};

const ErrorFallback = ({error, resetErrorBoundary}) => {
    window?.logApi?.error(error.message);
    window?.logApi?.error(error.stack);
    console.log(error.message);
    console.log(error.stack);
    return (
        <div role="alert" className="fatal-error">
            <p className="error-title">Ein Problem ist aufgetreten:</p>
            <h1>Oops, Foul, Elfmeter, Rote Karte ...</h1>
            <div>
                <a href="" title="Tactix neu laden" onClick={resetErrorBoundary}>
                    Tactix neu laden
                </a>
                <div>{error.message}</div>
                <div>{error.stack}</div>
            </div>
        </div>
    );
};

//loadDemoData(2);

const queryClient = new QueryClient();

const App = () => {
    const user = useUserStore((state) => state.user);
    const setUser = useUserStore((state) => state.setUser);
    const title = useInfoStore(state => state.title);
    const deviceId = useInfoStore(state => state.deviceId);

    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [loading, setLoading] = useState(true);

    const selectedLanguage = useSettingsStore((store) => store.selectedLanguage);
    Settings.defaultLocale = selectedLanguage;
    const setSelectedLanguage = useSettingsStore((store) => store.setSelectedLanguage);
    const mode = useInfoStore(state => state.mode);
    const setMode = useInfoStore(state => state.setMode);
    const showDrawer = useLayoutStore((state) => state.showDrawer);
    const setShowDrawer = useLayoutStore((state) => state.setShowDrawer);
    const closeAll = useLayoutStore(state => state.closeMenu);

    console.log(`mode: ${mode} version: ${version}`);

    // everything which must be loaded first, i.e. is vital
    useEffect(() => {
        const doLoad = async () => {
            try {
                const fontLoader = new FontFaceObserver('inter');
                await fontLoader.load();
            } catch (_) {
                console.log(`inter font can't be loaded, so you may see different font on canvas.`);
            }

            setMode(await getMode());

            if (mode === Modes.APP) {
                if (window.ReactNativeWebView.injectedObjectJson()) {
                    const injectionData = JSON.parse(window.ReactNativeWebView.injectedObjectJson());
                    // set initial user from app and add jwt to user
                    console.debug('App injectedObject: ', injectionData);
                    const userData = {...injectionData.user, jwt: injectionData.jwt, appId: injectionData.config.app_id};
                    const appLanguage = injectionData.config.language;
                    console.debug('set app user: ', userData, appLanguage);
                    setUser(userData);
                    setSelectedLanguage(appLanguage !== selectedLanguage ? appLanguage : defaultLang.code);
                }

            } // end mode app

            if (mode === Modes.BROWSER) {
            // browser mode
            // development only

            } // end browser mode

            if (mode === Modes.MONITOR) {
            // Monitor mode
            } // end Monitor mode

            setLoading(false);
        };

        doLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isProduction()) {
            return;
        }

        // if (mode === Modes.MONITOR) {
        LogRocket.init('lixnls/tactix');
        // }
    }, [mode]);

    // event listeners for communication with container app
    useEffect(() => {
        // WARNING: NEW EVENT BASED USER EVENTS ON APP VERSION >= 6.0.0
        // connected to mobile device
        const handleMenuToggle = (event) => {
            let eventData = event.detail;
            console.log('handleMenuToggle:', eventData);
            if (eventData) {
                switch (eventData) {
                case 'open':
                    setShowDrawer(true);
                    break;
                case 'close':
                    // needs to close all submenus too
                    closeAll();

                    break;
                }
            } 
        };

        const handleUserChange = (event) => {
            let eventData = event.detail;
            const userData = eventData ? {...eventData.user, jwt: eventData.jwt, appId: eventData.app_id} : null;
            console.log('handleUserChange:', userData);
            setUser(userData);
        };

        const handleLanguageChange = (event) => {
            let eventData = event.detail;
            console.log('handleLanguageChange:', eventData);
            // set default if not found
            setSelectedLanguage(eventData !== selectedLanguage ? eventData : defaultLang.code);
        };

        const handleInviteToLockerroom = (event) => {
            console.log('handleInviteToLockerroom:', event.detail);
            // let eventData = event.detail;
            // TODO handle invite to lockerroom
        };

        if (mode === Modes.APP) {
            window.addEventListener('toggleMenu', handleMenuToggle);
            window.addEventListener('userChange', handleUserChange);
            window.addEventListener('languageChange', handleLanguageChange);
            window.addEventListener('joinLockerroom', handleInviteToLockerroom);
            // params = await setupAppUserData(openingRef.current, activeRef.current, roomId, setRoomId, setSelectedTeam, userRef.current, setUser);
        } // end mode app

        if (mode === Modes.BROWSER) {
            // browser mode
            // development only

        } // end browser mode

        if (mode === Modes.MONITOR) {
            // Monitor mode
            // browser mode gets no user - so is using params
        } // end Monitor mode

        return () => {
            window.removeEventListener('toggleMenu', handleMenuToggle);
            window.removeEventListener('userChange', handleUserChange);
            window.removeEventListener('languageChange', handleLanguageChange);
            window.removeEventListener('joinLockerroom', handleInviteToLockerroom);
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, showDrawer]);

    useEffect(() => {
        if (!isProduction() || !user?.id) {
            return;
        }

        LogRocket?.identify(user?.id);
    }, [user]);

    useEffect(() => {
        console.log('device', title, deviceId);

        if (!isProduction() || !title || !deviceId) {
            return;
        }

        LogRocket?.identify(String(deviceId), {
            name: title
        });
    }, [title, deviceId]);

    useEffect(() => {
        function updateOnlineStatus() {
            setIsOnline(navigator.onLine);
        }

        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);

        return () => {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
    }, []);

    // we wait until the font was loaded so the canvas can render the correct font
    if (loading) {
        return null;
    }

    if (!isOnline && !isDevelopment()) {
        return <Box sx={styles.loadingInfoContainer}><Text>board.layout.is_offline</Text></Box>;
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => location.reload()}>
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={'de'}>
                <ThemeProvider theme={theme}>
                    <QueryClientProvider client={queryClient}>
                        <Bootstrap mode={mode}>
                            <VersionCheck mode={mode}/>
                            <Periodic/>
                            <CssBaseline/>
                            <Snackbar/>
                            <Layout/>
                        </Bootstrap>
                    </QueryClientProvider>
                </ThemeProvider>
            </LocalizationProvider>
        </ErrorBoundary>
    );
};

export default App;
