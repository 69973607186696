import {create} from 'zustand';

import {middleware} from 'store/zustand';

import {produce} from 'immer';

const useSnackbarStore = create(middleware(set => ({
    open: false,
    message: '',
    closeButton: true, // default
    variant: 'alert', // default
    alertSeverity: 'success', // warning, info, error
    show: (message, opts = {severity: 'success'}) => set(produce(draft => {
        if (typeof message !== 'string') {
            return;
        }

        draft.open = true;
        draft.message = message;
        draft.alertSeverity = opts.severity;
        draft.closeButton = opts.closeButton;
    })),
    hide: () => set(produce(draft => {
        draft.open = false;
    }))
})));

export default useSnackbarStore;
