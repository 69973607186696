import React, {forwardRef, useEffect} from 'react';

import {ButtonBase, MenuItem, Slide, Stack} from '@mui/material';

import {yupResolver} from '@hookform/resolvers/yup';

import lineups from 'assets/lineups/home';
import {Dialog, Text} from 'components';
import {useLayoutStore, useSnackbarStore} from 'store';

import {schema, useApiAdd} from './useTeamApi.js';

import {Form, FormField, SelectField, useForm} from 'form';

const styles = {
    button: {
        color: 'white.main',
        marginLeft: 4,
        fontSize: '16px'
    }
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const CreateDialog = ({client, onClose, onSave}) => {
    const setShowLoading = useLayoutStore((state) => state.setShowLoading);
    const showInfo = useSnackbarStore((state) => state.show);

    const {add, loading, data, errors} = useApiAdd();

    const doCancel = () => {
        onClose && onClose();
    };

    const doSave = async (formData) => {
        formData.client_id = client.id;

        try {
            const res = await add(formData);
            onSave && onSave(res);
        } catch (e) {
            showInfo(e.message, {severity: 'warning'});
        }
    };

    useEffect(() => {
        setShowLoading(loading);
        return () => {
            setShowLoading(false);
        };
    }, [loading, setShowLoading]);

    const rhf = useForm(data, errors, true, {resolver: yupResolver(schema)});

    return <Dialog
        maxWidth={false}
        pt={0}
        open={true}
        TransitionComponent={Transition}
        handleClose={onClose}
        title="team.action.create2"
    >
        <Form methods={rhf.methods} onSubmit={doSave}>
            <Stack direction="column" p={2}>
                <FormField name="name" label="team.name" autoFocus/>
                <FormField name="num_players" type="number" label="team.prefs.player.count"/>
                <SelectField label="team.lineup" name="lineup">
                    {lineups.map(lineup => <MenuItem key={lineup.name} value={lineup.name}>{lineup.name}</MenuItem>)}
                </SelectField>
                <Stack direction="row" justifyContent="flex-end" pb={1} pt={2}>
                    <ButtonBase disableRipple sx={styles.button} onClick={doCancel}>
                        <Text>ui.actions.cancel</Text>
                    </ButtonBase>
                    <ButtonBase disableRipple sx={styles.button} type="submit">
                        <Text>ui.actions.save</Text>
                    </ButtonBase>
                    <ButtonBase disableRipple sx={styles.button} type="submit">
                        <Text>ui.actions.save</Text>
                    </ButtonBase>
                </Stack>
            </Stack>
        </Form>
    </Dialog>;
};

export default CreateDialog;