import React, {useCallback, useEffect,useState} from 'react';

import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    Typography} from '@mui/material';

import {Dialog as BaseDialog, Text} from 'components';
import {isOk} from 'fetcher/useFetcher';
import {useInterval, useTranslation} from 'hooks';
import {usePairingStore} from 'module/pairing';

// import {useInfoStore} from 'store';
import {usePairingAdd} from './usePairing';

import {DateTime} from 'luxon';
import ReactQRCode from 'qrcode.react';

const styles = {
    dialogContainer: {
        align: 'center'
    },
    qrcodeContainer: {
        backgroundColor: '#fff', // keep this, otherwise phones have problem scanning the qrcode
        display: 'inline-flex',
        padding: 2
    },
    pairingCode: {
        fontSize: '45px',
        marginTop: '20px',
        marginBottom: '10px',
        letterSpacing: '20px', // increase letter spacing
    }
};

const Dialog = ({open, handleClose}) => {
    const translate = useTranslation();
    // const deviceId = useInfoStore(store => store.deviceId);
    // const deviceTitle = useInfoStore(store => store.deviceId);
    const setActivePairing = usePairingStore((state) => state.set);
    const activePairing = usePairingStore((state) => state.activePairing);

    const {add, loading} = usePairingAdd();
    const [code, setCode] = useState('');
    const [codeValidity, setCodeValidity] = useState(activePairing?.valid_until || null);
    let refreshTime = DateTime.fromISO(codeValidity).diff(DateTime.now()).milliseconds || 300000;

    useInterval(useCallback(async () => {
        // TODO add info message based on device type or location
        // for refreshing the pairing code every 5 minutes
        if (codeValidity && !activePairing.jwt) {

            console.log('Refreshing pairing code...', refreshTime / 1000);
            try {
                const res = await add('INFO MESSAGE');
                console.log('refresh pairing code successfully submitted', res);
                if (isOk(res) && typeof res == 'object') {
                    console.log('pairing code:', res.code);
                    setCodeValidity(res.valid_until);
                    setCode(res.code);
                    setActivePairing(res);
                }
            } catch (error) {
                console.error('Error submitting pairing code', error);
            }
        }
        // new code after 5 minutes * 60 seconds * 1000 -> 300000
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codeValidity, activePairing]), refreshTime 
    );

    useEffect(() => {
        const getPairingCode = async () => {
        // for refreshing the pairing code every 5 minutes
            try {
                const res = await add('INFO MESSAGE');
                console.log('initial pairing code successfully submitted', res);
                if (isOk(res) && typeof res == 'object') {
                    console.log('pairing code:', res.code);
                    // console.log('VALID UNTIL', DateTime.fromISO(res.valid_until).diff(DateTime.now()));
                    setCodeValidity(res.valid_until);
                    setCode(res.code);
                    setActivePairing(res);

                }
            } catch (error) {
                console.error('Error submitting pairing code', error);
            }
        // handleClose might not be wrapped into useCallback, therefore do not add it as dep
        // eslint-disable-next-line react-hooks/exhaustive-deps
        };

        // TODO maybe check if there is an existing pairing code before getting a new one
        getPairingCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <BaseDialog open={open} handleClose={handleClose} title={translate('app.pairing.pairing')}>
        {!activePairing.jwt ? (<>
            <DialogContent>
                <Typography variant="body1"><Text>app.pairing.info</Text></Typography>
            </DialogContent>
            <DialogContent sx={styles.dialogContainer} align={'center'}>
                {!loading && code !== '' ? <Box sx={styles.qrcodeContainer}>
                    <ReactQRCode value={`tactix://pairing?code=${code}`} level={'H'}/>
                </Box> : <CircularProgress color="inherit"/>}
                <Typography sx={styles.pairingCode} color="primary" variant="h1"> {code}</Typography>
                {codeValidity && <Timer dateTime={codeValidity} />}
            </DialogContent>
        </>)
            :(<>
                <DialogContent>
                    <Typography color="primary" variant="h1"><Text>app.pairing.message.success</Text></Typography>
                </DialogContent>
                <DialogContent sx={styles.dialogContainer} align={'center'}>
                    <Button onClick={handleClose} color="primary" variant="contained"> <Text>app.layout.message.close_window</Text></Button>
                </DialogContent>
            </>)
        }
        <DialogActions>
        </DialogActions>
    </BaseDialog>;
};

const Timer = ({dateTime}) => {
    const [timeLeft, setTimeLeft] = useState(DateTime.fromISO(dateTime).diff(DateTime.now()));

    useEffect(() => {
        const timerInterval = setInterval(() => {
            setTimeLeft(DateTime.fromISO(dateTime).diff(DateTime.now()));
        }, 1000);

        return () => clearInterval(timerInterval);
    }, [dateTime]);

    return (
    
        <Typography variant={'p'} color={'primary'}> {/* unTranslated */}Der Code ist noch {timeLeft.toFormat('mm:ss')} gültig.</Typography>
    );};

export default Dialog;
