import React, {useEffect, useState} from 'react';

import {Logout as LogoutIcon, TapAndPlay as TapAndPlayIcon} from '@mui/icons-material';
import {Box} from '@mui/material';
import {Button, ClickAwayListener, Popper, Stack} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';

import Icons from 'assets/icons';
import {ActionButton, Text} from 'components';
import {useBannersStore} from 'module/banner';
import {usePairingDelete} from 'module/pairing';
import {usePairingStore} from 'module/pairing';
import {useUserStore} from 'module/user';
import {useUserUtils} from 'module/user/useUser';
import {useLayoutStore} from 'store';

const styles = {
    container: {
        height: '60px',
        width: '7.692307%',
        marginLeft: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    iconButtonStyle: {
        color: 'white.main',
        borderRadius: 0,
        position: 'relative',
    },
    icon: {
        fill: '#ffffff',
        width: 24,
        height: 24,
    },
    iconPaired: {
        color: 'green.main'
    },
    pairingContainer: {
        width: '340px',
        position: 'absolute',
        top: '100%',
        marginTop: '10px',
        right: 0,
        backgroundColor: 'grey.darker',
        padding: '20px',
        zIndex: 999,
        color: 'white.main',
        fontSize: 17,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    pairingContainerTitle: {
        textAlign: 'left',
        width: '100%',
        fontSize: 17,
        fontWeight: 'bold',
        marginBottom: '10px',
        borderBottom: '1px solid',
        borderColor: 'white.main',
        paddingBottom: '10px',
    },
    pairingContainerSubTitle: {
        textAlign: 'left',
        fontSize: 15,
        fontWeight: 'bold',
        marginBottom: '10px',
    },
    pairingLogoutButton: {
        width: '100%',
    }
};

const Pairing = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const bannersVisible = useBannersStore((store) => store.visible);
    const {del, loading} = usePairingDelete();
    const activePairing = usePairingStore((state) => state.activePairing);
    const queryClient = useQueryClient();

    const user = useUserStore(state => state.user);
    const {isAuthenticated, logout} = useUserUtils();
    const setShowPairing = useLayoutStore((state) => state.setShowPairing);

    const handleIconClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const doLogout = async () => {
        setAnchorEl(null);
        await del(activePairing?.id);
        queryClient.removeQueries({queryKey: ['user-get'], exact: true});
        
        logout();
    };

    useEffect(() => {
        if (bannersVisible) {
            handleClickAway();
        }
    }, [bannersVisible]);

    if (!isAuthenticated) {
        return <Box sx={styles.container} marginLeft={0}>
            <Icons.Connect style={styles.icon} onClick={() => setShowPairing(true)}/>
        </Box>;
    }

    return <Box sx={styles.container} marginLeft={0}>
        <ClickAwayListener onClickAway={handleClickAway}>
            <Stack direction="row">
                <ActionButton sx={styles.iconButtonStyle} onClick={handleIconClick}>
                    <TapAndPlayIcon sx={styles.iconPaired}/>
                </ActionButton>

                <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-end">
                    <Box sx={styles.pairingContainer}>
                        <Box sx={styles.pairingContainerTitle}><Text>app.pairing.message.connected</Text>: {user?.firstname} {user?.lastname}</Box>
                        <Box sx={styles.pairingContainerSubTitle}><Text>app.pairing.message.connection_time</Text></Box>
                        <Button
                            sx={styles.pairingLogoutButton} onClick={doLogout} disableRipple={true} loading={loading}
                            color="error" variant="contained" startIcon={<LogoutIcon/>}>
                            <Text>account.action.logout</Text>
                        </Button>
                    </Box>
                </Popper>
            </Stack>
        </ClickAwayListener>
    </Box>;
};

export default Pairing;
