import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Box, Grid, Stack, useMediaQuery, useTheme} from '@mui/material';

import {useAnalytics} from 'hooks';
import {useLockerRoomStore} from 'module/lockerRoom/zustand';
import {useSheet} from 'module/sheet/useSheet';
import {useLayoutStore} from 'store';
import {usePrefsStore} from 'store/prefs';

import {ColorTool, DrawingTool, PlayerTool} from './components';
import {Flyout, ToolbarButton} from './components';

const styles = {
    root: {
        backgroundColor: 'grey.darkest',
        position: 'relative',
        height: {
            mobile: '50px',
            desktop: '70px',
        },
        '& > *': {
            height: {
                mobile: 50,
                desktop: 70,
            },
        },
    },
    container: {
        height: {
            mobile: '50px',
            desktop: '70px',
        },
    },
    toolbarPlaceHolder: {
        width: {
            mobile: '12.5%',
            desktop: '7.692307%',
        },
    },
};

//const defaultIconColor = 'black.main';

const defaultToolOptions = {
    color: 'black.main'
};

const Toolbar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [flyout, setFlyout] = useState(undefined);

    const tool = usePrefsStore((state) => state.tool);
    const setTool = usePrefsStore((state) => state.setTool);
    const color = usePrefsStore((state) => state.color);
    const prefs = usePrefsStore((state) => state.prefs);

    const setShowTimeline = useLayoutStore((state) => state.setShowTimeline);
    const setShowLineup = useLayoutStore((state) => state.setShowLineup);

    const active = useLockerRoomStore((state) => state.active);
    const editor = useLockerRoomStore((state) => state.editor);

    const {getNumFrames} = useSheet();
    const numFrames = getNumFrames();

    const sendAnalytics = useAnalytics();

    const toolSelected = (t) => {
        console.log('toolSelected', t, tool);
        if (tool !== t) {
            sendAnalytics('tool', t);
            setTool(t);
        }

        setTimeout(() => setFlyout(undefined), 100);
    };

    const showFlyout = (tool, anchor, children) => {
        setFlyout({tool, anchor, children});
    };

    const closeFlyout = () => {
        setFlyout(undefined);
    };

    const toggleFlyout = (tool, element, e) => {
        if (flyout?.tool === tool) {
            closeFlyout();
        } else {
            showFlyout(tool, e.currentTarget, element);
        }
    };

    const toolWithFlyout = (newTool, element, e) => {
        // console.log(tool, newTool);
        if (tool === newTool) {
            toggleFlyout(newTool, element, e);
        } else {
            toolSelected(newTool);
        }
    };

    if (active && !editor) {
        return null;
    }

    return <Grid
        container
        direction="row"
        justifyContent={'space-between'}
        sx={styles.root}
    >
        <Grid item xs>
            {flyout !== undefined &&
                <Flyout open={true} onClose={closeFlyout} anchorEl={flyout?.anchor}>{flyout.children}</Flyout>}

            <Stack direction="row" sx={styles.container}>
                {/* 1. Select-Tool */}
                <ToolbarButton
                    icon="select"
                    dropdown={false}
                    selected={tool === 'select'}
                    onClick={() => toolSelected('select')}/>

                {/* 2. Zeichen-Tool */}
                <ToolbarButton
                    icon={prefs.draw[prefs.draw.type]}
                    dropdown={true}
                    selected={tool === 'draw'}
                    onClick={(e) => toolWithFlyout('draw', <DrawingTool onSelect={t => toolSelected(t)}/>, e)}
                />

                {/* 3. Text-Tool */}
                <ToolbarButton
                    icon="text"
                    dropdown={false}
                    selected={tool === 'text'}
                    onClick={() => toolSelected('text', defaultToolOptions)}/>

                {/* 4. Player-Tool */}
                <ToolbarButton
                    icon={prefs.player?.type || 'player'} dropdown={true} selected={tool === 'player'}
                    onClick={(e) => toolWithFlyout('player', <PlayerTool
                        onSelect={() => toolSelected('player')}/>, e)}
                />

                {/* 5. Lineup-Tool */}
                <ToolbarButton
                    icon="lineup"
                    dropdown={false}
                    onClick={() => {
                        closeFlyout();
                        setShowLineup(true);
                    }}
                />

                {/* 6. Color-Tool */}
                <ToolbarButton
                    icon="colorQuadrat" dropdown={true} color={color.value1}
                    selected={flyout?.tool === 'color'}
                    onClick={(e) => toggleFlyout('color', <ColorTool onSelect={closeFlyout}/>, e)}
                />

                {/* 7. Placeholder */}
                {isMobile && <Box sx={styles.toolbarPlaceHolder}/>}

                {!isMobile && <>
                    <Box sx={styles.toolbarPlaceHolder}/>
                    <Box sx={styles.toolbarPlaceHolder}/>
                    <Box sx={styles.toolbarPlaceHolder}/>
                    <Box sx={styles.toolbarPlaceHolder}/>
                    <Box sx={styles.toolbarPlaceHolder}/>
                    <Box sx={styles.toolbarPlaceHolder}/>
                </>}

                {/* 8. Spielzug */}
                <ToolbarButton
                    icon="timeline" badge={numFrames > 0 ? numFrames : undefined}
                    onClick={() => setShowTimeline(true)}/>

            </Stack>
        </Grid>
    </Grid>;
};

Toolbar.propTypes = {
    onAction: PropTypes.func,
};

export default Toolbar;
