import {useCallback} from 'react';

import {useFetcher} from 'fetcher';
import {getEnv, isTstEnvironment} from 'lib/env';

const CONTROL_URL = getEnv('CONTROL_URL');

export const useGetVersion = () => {
    let environ = 'prod';
    if (isTstEnvironment()) {
        environ = 'tst';
    }
  
    const {loading, fetch, data, hasError} = useFetcher(CONTROL_URL + 'version/{env}', {
        method: 'GET',
    });

    const getVersion = useCallback(async (platform) => {
        // Available values : tactix-board, tactix-desktop, tactix-shop-app
        let platformString = '';
        switch (platform) {
        case 'app':
            platformString = 'tactix-shop-app';
            break;
        case 'desktop':
            platformString = 'tactix-desktop';
            break;
        default:
            platformString = 'tactix-board';
            break;
        }

        return await fetch({
            urlParams: {
                env: environ,
            },
            params: {
                app: platformString,
            }
        });
    }, [fetch , environ]);

    return {getVersion, loading, versionData: data, hasError};
};