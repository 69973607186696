import React, {useState} from 'react';

import {ArrowDownward as ArrowDownIcon, ArrowUpward as ArrowUpIcon} from '@mui/icons-material';
import {
    Avatar,
    Box,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material';
import {useMediaQuery, useTheme} from '@mui/material';

import Icons from 'assets/icons';
import {homeLineup} from 'assets/lineups';
import {getNextPlayerNumber} from 'canvas/Drawer/player-utils';
import {useCanvasStore} from 'canvas/zustand';
import {Drawer, Text} from 'components';
import {TeamLineup} from 'module/client/team';
import {DraggablePlayer} from 'module/client/team/member';
import {useDrawAnonLineup} from 'module/client/team/useLineup';
import {useApiRead} from 'module/client/team/useTeamApi';
import {useSettingsStore} from 'module/settings/zustand';
import {useUserUtils} from 'module/user/useUser';
import {useLayoutStore, usePrefsStore} from 'store';

const styles = {
    drawer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        overflow: 'visible !important', // to allow dragging players out of the drawer
        width: 'auto',
        minWidth: '150px',
        display: 'flex',
        flexDirection: 'column',
    },
    drawerInvisible: {
        minWidth: '0',
        width: '0',
        padding: '0',
    },
    iconBoxGroup: {
        padding: '10px',
        '&:last-child': {
            marginBottom: '40px',
        }
    },
    iconBox: {
        width: '100%',
        marginBottom: '5px',
    },
    iconBoxText: {
        fontSize: '14px',
    },
    icon: {
        height: '80px',
        overflow: 'hidden',
    },
    toggleButtonGroup: {
        padding: '10px',
        justifyContent: 'center',
    },
    buttonInvisible: {
        minWidth: '0',
        width: '0',
        padding: '0',
        overflow: 'hidden',
    },
    toggleButton: theme => ({
        padding: '20px',
        color: 'white.main',
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
            padding: '12px',
        },
    }),
    playerDisabled: {
        opacity: 0.5,
        overflow: 'hidden',

    },
    playerShirt: {
        overflow: 'hidden',

        position: 'relative',
        '& span': {
            textAlign: 'center',
            position: 'absolute', top: 40, left: 0, width: '100%',
        }
    },
    lineUpContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: '0', // for firefox
        paddingRight: '5px',
        marginBottom: 1,
    },
    lineUpScrollContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: '0', // for firefox
        width: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            height: '100%',
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'grey.light',
            borderRadius: 2
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'grey.lightest',
            borderRadius: 2
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'grey.lightest',
            borderRadius: 2
        },
    }
};

const AnonLineup = () => {
    const [direction, setDirection] = useState('up');
    const lineupIsDragging = useLayoutStore((state) => state.lineupIsDragging);

    const color = usePrefsStore((state) => state.color);
    const playerPrefs = usePrefsStore((state) => state.prefs.player);

    const drawLineup = useDrawAnonLineup();
    const theme = useTheme();

    const onClick = (name) => {
        drawLineup(name, direction, playerPrefs.type, color, playerPrefs.size);
    };

    return <Stack direction="column" alignItems="center" sx={{...styles.lineUpContainer, ...(lineupIsDragging && styles.drawerInvisible)}}>
        <Player/>
        <Stack direction="row" sx={{...(lineupIsDragging && styles.buttonInvisible)}} >
            <ToggleButtonGroup
                color="primary"
                value={direction}
                exclusive
                onChange={(e, v) => setDirection(v)}
                sx={{...styles.toggleButtonGroup, ...(lineupIsDragging && styles.buttonInvisible)}}

            >
                <ToggleButton sx={{...styles.toggleButton(theme), ...(lineupIsDragging && styles.buttonInvisible)}} value="up"><ArrowUpIcon color="primary"/></ToggleButton>
                <ToggleButton sx={{...styles.toggleButton(theme), ...(lineupIsDragging && styles.buttonInvisible)}} value="down"><ArrowDownIcon color="primary"/></ToggleButton>
            </ToggleButtonGroup>
        </Stack>
        <Stack direction="column" sx={styles.lineUpScrollContainer}>
            {homeLineup.map(l => <Stack key={l.name} onClick={() => onClick(l.name)} sx={styles.iconBoxGroup}>
                <Box sx={styles.iconBox} display="flex" justifyContent="center">
                    <l.Icon style={styles.icon}/>
                </Box>
                <Typography align="center" sx={styles.iconBoxText}>{l.name}</Typography>
            </Stack>)}
        </Stack>
    </Stack>;
};

const PlayerCircle = ({number, colors, disabled}) => {
    return <Avatar sx={{...colors, ...(disabled && styles.playerDisabled)}}>{number}</Avatar>;
};

const PlayerShirt = ({number, colors, disabled}) => {
    const svgStyle = {width: '50px', fill: colors.bgcolor};

    return <Box sx={{...styles.playerShirt, ...(disabled && styles.playerDisabled)}}>
        <Icons.Shirt style={svgStyle}>{number}</Icons.Shirt>
        <Typography component="span" variant="h2" sx={{color: colors.color}}>{number}</Typography>
    </Box>;
};

export const Player = () => {
    const canvas = useCanvasStore(state => state.canvas);
    // watch for canvas size to trigger a re-rendering, otherwise next number will not be updated
    useCanvasStore(state => state.canvas?.size());
    const theme = useTheme();
    
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const lineupIsDragging = useLayoutStore((state) => state.lineupIsDragging);

    const color = usePrefsStore(state => state.color);
    const nextNumber = getNextPlayerNumber(canvas, color.name);

    const colors = {
        color: color.value2, bgcolor: color.value1
    };

    const player = {
        team: color.name,
        number: nextNumber,
    };

    return <Stack direction="row" alignItems="center" gap={2}>
        <DraggablePlayer type="playerCircle" player={player}>
            {lineupIsDragging === false || (isMobile && lineupIsDragging === 'playerCircle') ?
                <PlayerCircle number={nextNumber} colors={colors} />
                : null}
        </DraggablePlayer>
        <DraggablePlayer type="player" player={player}>
            {lineupIsDragging === false || (isMobile && lineupIsDragging === 'player') ?
                <PlayerShirt number={nextNumber} colors={colors} />
                : null}
        </DraggablePlayer>
    </Stack>;
};

const LineupTool = () => {
    const [type, setType] = useState('anon');
    const {isAuthenticated} = useUserUtils();
    const theme = useTheme();

    const showLineup = useLayoutStore((state) => state.showLineup);
    const setShowLineup = useLayoutStore((state) => state.setShowLineup);
    const selectedTeam = useSettingsStore((state) => state.selectedTeam);
    const lineupIsDragging = useLayoutStore((state) => state.lineupIsDragging);

    const queryTeam = useApiRead({teamId: selectedTeam});

    const doClose = () => {
        setShowLineup(false);
    };

    const onChange = (v) => {
        if (v !== null) {
            setType(v);
        }
    };

    return <Drawer
        open={showLineup} onClose={doClose} sx={{...styles.drawer, ...(lineupIsDragging && styles.drawerInvisible)}}
        slotProps={{backdrop: {invisible: true}}}>
        <ToggleButtonGroup
            color="primary"
            value={type}
            exclusive
            onChange={(_e, v) => onChange(v)}
            sx={{...styles.toggleButtonGroup, ...(lineupIsDragging && styles.buttonInvisible)}}
        >
            <ToggleButton value="anon" sx={{...styles.toggleButton(theme), ...(lineupIsDragging && styles.buttonInvisible)}} ><Text>board.toolbar.lineup</Text></ToggleButton>
            {isAuthenticated && <ToggleButton value="team" sx={{...styles.toggleButton(theme), ...(lineupIsDragging && styles.buttonInvisible)}} ><Text>team.team</Text></ToggleButton>}
        </ToggleButtonGroup>
        {type === 'team' && <TeamLineup team={queryTeam.data}/>}
        {type === 'anon' && <AnonLineup/>}

    </Drawer>;
};

export default LineupTool;