import {useCallback} from 'react';

import {isOk, useFetcher} from 'fetcher';
import {getEnv} from 'lib/env';

const CONTROL_URL = getEnv('CONTROL_URL');

export const useApiLoad = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'pairing/{id}', {
        method: 'GET',
    }, {}, true);

    const load = useCallback(async (pairingId) => {
        let pairing = {};

        const res = await fetch({
            urlParams: {
                id: pairingId
            },
        });

        if (isOk(res) && typeof res.data === 'object') {
            pairing = res.data;
        }

        return pairing;
    }, [fetch]);

    return {load, loading};
};

export const useApiAdd = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'pairing/', {
        method: 'POST',
    }, undefined, true);

    const add = useCallback(async (data) => {

        const res = await fetch({
            data: data
        });

        if (isOk(res) && typeof res.data === 'object') {
            return res.data;
        }
    }, [fetch]);

    return {add, loading};
};

export const useApiDelete = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'pairing/{id}', {
        method: 'DELETE',
    }, undefined, true);

    const del = useCallback(async (pairingId) => {

        const res = await fetch({
            urlParams: {
                id: pairingId
            },
        });

        if (isOk(res)) {
            return res;
        }
    }, [fetch]);

    return {del, loading};
};
