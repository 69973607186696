import React, {useEffect} from 'react';

import {
    Box,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';

import {DEFAULT_SIZE, getPlayerSizeOptions, sizeOptions} from 'canvas/Drawer/player-utils';
import {PremiumLock, Text} from 'components';
import {unTranslated} from 'hooks';
import AboInfo from 'layout/components/toolbar/components/AboInfo';
import {useLicenseManager} from 'module/user';
import {usePrefsStore} from 'store';

import FlyoutButton from './FlyoutButton';

const styles = {
    root: {
        marginLeft: '0.5%',
    },
    lockContainer: {
        position: 'relative',
        padding: 20,
    }
};

const PlayerTool = ({onSelect}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));
    const {hasAccess, hasFullAccess} = useLicenseManager();

    const prefs = usePrefsStore((state) => state.prefs.player);
    const setPref = usePrefsStore((state) => state.setPref);

    const onSizeChanged = (_e, s) => {
        setPref('player', {size: Number(s)});
    };

    const onClick = (name) => {
        setPref('player', {type: name});
        onSelect(name);
    };

    const prefsSize = prefs.size || DEFAULT_SIZE;
    const playerSizeOptions = getPlayerSizeOptions(prefsSize);
    const circleSizeOptions = getPlayerSizeOptions(prefsSize);

    const shrink = isMobile ? 0.5 : 0.7;
    const playerSize = playerSizeOptions.size * shrink;
    const circleSize = circleSizeOptions.size * shrink;
    useEffect(() => {
        if (!hasAccess('player-sizes', prefsSize).access) {
            Object.keys(sizeOptions).map((key) => {
                if (hasAccess('player-sizes', sizeOptions[key].label).access) {
                    // if size is not available for user, set to first found size
                    setPref('player', {size: Number(key)});
                }

            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefsSize]);

    return <Stack direction="column" sx={styles.root}>
        <Typography variant="h1" color="grey.mainlight" mb={3}><Text>board.toolbar.position_player</Text></Typography>
        <Typography variant="h2" color="grey.mainlight" mb={1}><Text>board.toolbar.size</Text></Typography>
        <FormControl style={styles.lockContainer}>
            <RadioGroup row name="player-size" onChange={onSizeChanged} value={prefsSize}>
                {Object.keys(sizeOptions).filter((el) => hasAccess('player-sizes', sizeOptions[el].label).access).map((key) => <FormControlLabel key={key} value={key} control={<Radio />} label={sizeOptions[key].label}/>)}
            </RadioGroup>
        </FormControl>
        {!hasFullAccess('player-sizes') &&
            <Stack onClick={() => console.log('LINK TO PREMIUM PAGE')} style={styles.lockContainer}>
                <PremiumLock text={unTranslated('Weitere Größen mit PREMIUM')} />
                <RadioGroup row name="player-size" value={prefsSize}>
                    {Object.keys(sizeOptions).filter((el) => !hasAccess('player-sizes', sizeOptions[el].label).access).map((key) => <FormControlLabel key={key} value={key} control={<Radio />} label={sizeOptions[key].label}/>)}
                </RadioGroup>
            </Stack>
        }
        <Box mb={5}/>
        <Typography variant="h2" color="grey.mainlight" mb={1}><Text>board.toolbar.shirt_magnet</Text></Typography>
        <Stack direction="row" flexWrap="wrap" useFlexGap={true} mb={10}>
            <FlyoutButton icon="Tshirt" onClick={() => onClick('player')} selected={prefs?.selected === 'player'} iconSize={playerSize} edge="start"/>
            <FlyoutButton icon="CircleFull" onClick={() => onClick('playerCircle')} selected={prefs?.selected === 'playerCircle'} iconSize={circleSize}/>
        </Stack>

        {/*<Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={styles.placeHolder}>
            Werbefläche
        </Box>*/}
        {!hasFullAccess('player-sizes') && <AboInfo /> }
    </Stack>;
};

export default PlayerTool;
