import React, {useEffect, useState} from 'react';

import {Box, Button, ClickAwayListener, Popper,Stack,Typography} from '@mui/material';

import {useAction} from 'canvas/canvas-actions';
import {useCanvasSave} from 'canvas/useCanvas';
import {useCanvasStore} from 'canvas/zustand';
import {Text} from 'components';
import {isDevelopment} from 'lib/env';
import {Modes as modes} from 'lib/tactix';
import {useGroup} from 'module/group/useGroup';
import {usePairingStore} from 'module/pairing';
import {SheetDialog} from 'module/sheet';
import {useSheet} from 'module/sheet/useSheet';
import {hasSysInfoAPi} from 'module/sysInfo/sysInfo';
import {useLicenseManager} from 'module/user';
import {useUserUtils} from 'module/user/useUser';
import {useInfoStore, useLayoutStore, usePrefsStore, useSnackbarStore} from 'store';

import {InfobarButton, InfobarClock, InfobarInfo, InfobarLogo, InfobarOptions, InfobarPairing, InfobarWwan} from './index';

const styles = {
    headerHolder: {
        height: {
            desktop: '60px',
            mobile: '50px'
        },
    },
    moreButtonHolder: {
        width: {
            mobile: '12.5%',
            desktop: '7.692307%',
        }
    },
    placeHolder: {
        width: '7.692307%',
    },
    newSheetContainer: {
        width: '340px',
        backgroundColor: 'grey.darker',
        padding: '20px',
        zIndex: 999,
        color: 'white.main',
    },
    sheetPopperTitle: {
        marginBottom: '10px',
        borderBottom: '1px solid',
        borderColor: 'white.main',
        paddingBottom: '10px',
    },
    newSheetButton: {
        backgroundColor: 'red.dark',
        cursor: 'pointer',
    },
    newSheetButton2: {
        backgroundColor: 'white.main',
        marginBottom: 1,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'white.darkest',
        }
    },
};

const Infobar = ({disabled}) => {
    // const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const {hasAccess} = useLicenseManager();

    const {dispatch} = useAction();

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElNew, setAnchorElNew] = useState(null);
    const [showSheetDialog, setShowSheetDialog] = useState(false);

    const setShowDrawer = useLayoutStore((state) => state.setShowDrawer);

    const setShowLoading = useLayoutStore((state) => state.setShowLoading);

    const {selectedGroup: group, selected: selectedGroup} = useGroup();
    const {selectedSheet: sheet, sheets: sheetsByGroup} = useSheet(selectedGroup);

    const {save: saveCanvas, loading} = useCanvasSave();

    const dirty = useCanvasStore((state) => state.dirty);
    const canvasStatesIndex = useCanvasStore((state) => state.canvasStatesIndex);
    const canvasStates = useCanvasStore((state) => state.canvasStates);

    const showInfo = useSnackbarStore((state) => state.show);

    const background = usePrefsStore((state) => state.background);

    const mode = useInfoStore(state => state.mode);
    const activePairing = usePairingStore((state) => state.activePairing);
    const {isAuthenticated} = useUserUtils();
    const setShowLogin = useLayoutStore((state) => state.setShowLogin);
    const save = async () => {
        console.log('Saving...', isAuthenticated);
        if (!isAuthenticated) {
            setShowLogin(true);
            return;
        }

        try {
            await saveCanvas(sheet, {background});
        } catch (e) {
            showInfo(e.message, {severity: 'warning'});
            return;
        }

        showInfo('board.sheet.action.save_success');
    };

    const showInfoOptions = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const openSheetDialog = (event) => {
        if (dirty) {
            setAnchorElNew(anchorElNew ? null : event.currentTarget);
        } else {
            setShowSheetDialog(true);
        }
    };

    const closeSheetPopper = () => {
        setAnchorElNew(null);
    };

    const openSheetDialogInPopper = () => {
        setAnchorElNew(null);
        setShowSheetDialog(true);
    };

    const closeOptions = () => {
        setAnchorEl(null);
    };

    const closeSheetDialog = () => {
        setShowSheetDialog(false);
    };

    const newSheetSaved = (_sheet) => {
        closeSheetDialog();
    };

    const hasSysInfo = hasSysInfoAPi();

    useEffect(() => {
        setShowLoading(loading);
    }, [loading, setShowLoading]);
    return (
        <Stack direction="row" alignItems="center" sx={styles.headerHolder}>

            {showSheetDialog && <SheetDialog open={showSheetDialog} onClose={closeSheetDialog} onSave={newSheetSaved}/>}

            {/* Logo-Holder */}
            <InfobarLogo/>

            {/* Menue-Holder */}
            {mode !== modes.APP ? (
                <InfobarButton
                    icon="burger"
                    disabled={disabled}
                    onClick={() => setShowDrawer(true)}
                />
            ): <Box sx={styles.placeHolder}/>}
            {/* Folien-Thema-Holder */}
            <InfobarInfo group={group} sheet={sheet} sheets={sheetsByGroup}/>

            {/* preview button */}
            <InfobarButton
                icon="back"
                disabled={disabled} onClick={() => dispatch('previous')}
                color={canvasStatesIndex > 0 ? 'white.main' : 'grey.light'}/>

            {/* next button */}
            <InfobarButton
                icon="forward"
                disabled={disabled} onClick={() => dispatch('redo')}
                color={(canvasStatesIndex + 1 < canvasStates.length) ? 'white.main' : 'grey.light'}/>

            {/* new-folie-button */}
            {hasAccess('group-sheets')?.limit?.max > sheetsByGroup.length &&
           ( <ClickAwayListener onClickAway={closeSheetPopper}>
               <Stack sx={styles.moreButtonHolder}>
                   <InfobarButton icon="new" fullsize={true} onClick={openSheetDialog}/>

                   <Popper
                       sx={styles.newSheetContainer} open={Boolean(anchorElNew)} anchorEl={anchorElNew}
                       placement={'bottom-end'}
                   >
                       <Stack>
                           <Typography sx={styles.sheetPopperTitle} variant="h1"><Text>global.information</Text>:</Typography>
                           <Typography mb={1} variant="h3"><Text>board.sheet.messages.not_saved</Text></Typography>
                           <Typography mb={2} variant="subtitle1"><Text>board.sheet.messages.not_saved_info</Text></Typography>
                           {dirty && <Button sx={styles.newSheetButton2} onClick={save} disableRipple={true} variant="contained" ><Text>board.sheet.action.save</Text></Button>}
                           <Button sx={styles.newSheetButton} onClick={openSheetDialogInPopper} disableRipple={true} color="error" variant="contained" ><Text>board.sheet.action.create</Text></Button>

                       </Stack>
                   </Popper>
               </Stack>
           </ClickAwayListener>)}

            {/* save button */}
            <InfobarButton
                icon="save"
                onClick={save}
                color={dirty ? 'red.dark' : 'grey.light'}/>

            {/* settings button */}
            <ClickAwayListener onClickAway={closeOptions}>
                <Stack sx={styles.moreButtonHolder} direction={'row'}>
                    <InfobarButton
                        fullsize={true}
                        icon="more"
                        disabled={disabled} 
                        onClick={showInfoOptions}
                        selected={Boolean(anchorEl)}/>

                    <Popper
                        sx={{zIndex: 13}} open={Boolean(anchorEl)} anchorEl={anchorEl}
                        placement={'bottom-end'}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}>
                        <InfobarOptions onComplete={closeOptions}/>
                    </Popper>
                </Stack>
            </ClickAwayListener>
            {/* pairing info button */}
            {mode !== modes.APP && activePairing?.jwt ? <InfobarPairing/> : <></>}
            {/* clock // board */}
            {mode !== modes.APP &&
                <>
                    {((isDevelopment() || mode === modes.MONITOR) && hasSysInfo) ? <InfobarWwan/> : <Box sx={styles.placeHolder}></Box>}
                    <InfobarClock/>
                </>
            }

        </Stack>
    );
};

export default Infobar;
