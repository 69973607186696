import {useCallback} from 'react';

import {usePairingStore} from 'module/pairing';

import {useApiAdd, useApiDelete,useApiLoad} from './usePairingApi';

const generatePairingCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';

    for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);

        if (i % 2 === 0) {
            // Even index => letter
            result += characters[randomIndex];
        } else {
            // Odd index => number
            const numbers = '0123456789';
            const randomNumberIndex = Math.floor(Math.random() * numbers.length);
            result += numbers[randomNumberIndex];
        }
    }

    return result;
};

export const usePairingAdd = () => {
    const {add: backendAdd, loading} = useApiAdd();

    const add = useCallback(async (info) => {
        let code = generatePairingCode();
        let res = await backendAdd({info: info, code});
        return {...res, code};

    }, [backendAdd]);

    return {add, loading};
};

export const usePairingLoad = () => {
    const {load: backendLoad, loading} = useApiLoad();

    const pairing = useCallback(async (id) => {
        if (!id) return;
        
        return await backendLoad(id);

    }, [ backendLoad]);

    return {pairing, loading};
};

export const usePairingDelete = () => {
    const removeActivePairing = usePairingStore((state) => state.unset);

    const {del: backendDelete, loading} = useApiDelete();

    const del = useCallback(async (id) => {
        await backendDelete(id);
        removeActivePairing();
    }, [backendDelete, removeActivePairing]);

    return {del, loading};
};
