import React from 'react';

import {useMediaQuery, useTheme} from '@mui/material';

import {DndContext, MouseSensor, TouchSensor, useSensor, useSensors} from '@dnd-kit/core';
import {snapCenterToCursor} from '@dnd-kit/modifiers';

import {CANVAS_HEIGHT, CANVAS_WIDTH} from 'canvas/canvas-helper';
import {getNextPlayerNumber} from 'canvas/Drawer/player-utils';
import {useCanvasStore} from 'canvas/zustand';
import {createPlayer} from 'module/client/team/useLineup';
import {useLayoutStore} from 'store';
import {usePrefsStore} from 'store';

const DnD = ({children}) => {
    const canvas = useCanvasStore(state => state.canvas);

    const prefs = usePrefsStore((state) => state.prefs.player);
    const color = usePrefsStore((state) => state.color);
    const theme = useTheme();

    const setLineupIsDragging = useLayoutStore((state) => state.setLineupIsDragging);
    
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const mouseSensor = useSensor(MouseSensor);
    const touchSensor = useSensor(TouchSensor);

    const sensors = useSensors(
        mouseSensor,
        touchSensor,
    );

    const handleDragStart = (e) => {
        // console.log('dragstart', e?.active?.data?.current?.player?.type);
        // hide drawer if dragging on mobile 
        if (isMobile) {
            // type is needed to display the active drag element when dragging on mobile
            setLineupIsDragging(e?.active?.data?.current?.player?.type || true);
        }
    };

    const handleDragMove = (_e) => {
        // console.log(e.activatorEvent.target.getBoundingClientRect());
        // console.log('move', e.delta.x, e.delta.y, e.activatorEvent.x, e.activatorEvent.y);
        // const viewPortPos = e.activatorEvent.target.getBoundingClientRect(); // viewport position of the draggable
        // console.log('move', viewPortPos.x, viewPortPos.y);
    };

    const handleDragEnd = (e) => {
        // console.log('dragend', e);
        if (isMobile) {
            setLineupIsDragging(false);
        }

        const team = e.active.data.current.team;
        const player = e.active.data.current.player;

        const canvasViewPort = canvas.lowerCanvasEl.getBoundingClientRect();
        const viewPortPos = e.activatorEvent.target.getBoundingClientRect(); // viewport position of the draggable

        const scale = 1 / canvas.getZoom();
        let x = viewPortPos.x - canvasViewPort.x + (viewPortPos.width / 2);
        let y = viewPortPos.y - canvasViewPort.y + (viewPortPos.height / 2);

        x = Math.round(x * scale);
        y = Math.round(y * scale);

        console.log(x, y, scale, viewPortPos.x, viewPortPos.y);

        if (e.over.id !== 'canvas-container' || x > CANVAS_WIDTH || x < 0 || y > CANVAS_HEIGHT || y < 0) {
            return;
        }

        if (player && team) {
            const teamPrefs = team.prefs.player;
            const image = team.prefs.player.image ? player.user?.image : null;

            createPlayer(canvas, player, prefs.type,
                {value1: teamPrefs.color1 || color.value1, value2: teamPrefs.color2 || color.value2, textColor: teamPrefs.color2 || color.textColor},
                prefs.size,
                image,
                x, y,
                {
                    team: team.id,
                    playerId: player.id,
                    position: player.position
                }
            );
        } else if (player) {
            player.number = getNextPlayerNumber(canvas, player.team);

            // prefs.type
            createPlayer(canvas, player, player.type,
                color,
                prefs.size,
                null,
                x, y,
                {
                    team: player.team,
                }
            );
        }

        // console.log(canvas);
    };

    return <DndContext
        modifiers={[snapCenterToCursor]}
        sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd}
        onDragMove={handleDragMove}>
        {children}
    </DndContext>;
};

export default DnD;
