// accepts usernumber 0-9 returns test db user object
// let userId = params.get('userId');
import MODULE_OPTIONS_BASIC from './module_options_basic.json';
import MODULE_OPTIONS_PREMIUM from './module_options_premium.json';
export function getTestUser(userId) {
  let myId = userId;
  let myJWT = '';
  let myFirstname = '';
  let myLastname = '';
  let myAppId = 'adminAppId01';
  let myImage = null;
  let modules = [];

  switch (userId) {
    case '0':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTc1MjRlNzMyMWY2NTgwMTdjMTUiLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.heNPnyxi98wU0_hu7UCMSaD8NybOxkdQVKzObrubt8g';
      myId = '656f17524e7321f658017c15';
      myAppId =
        'OLMUVN3ZG4X34AXYU6GNCP2UMX6JNRWOVOHVCWP34YW36EIKHEQVUJI4QDJK7EISLLPJKGMCIFSB5HQZJPY7JRJDXHCMZ6U6QMQK7XQ';
      myFirstname = 'firstName_test1000';
      myLastname = 'firstName_test1000';
      break;
    case '1':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTZkOWI1NjAzYjlhZjcwMDM4NjEiLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.W3BHV87et9Optc4WZ00l5mqcyaGOIh3k0jNK3co_kSs';
      myId = '656f16d9b5603b9af7003861';
      myAppId =
        '6OC2NKXGYCAEA7TDIBWEWMI4W7ZJWZR2C4KNZRF2YVVGYX7G5XXT6HN65XVM22GF624BNSVAESYRELY735U6RLWJDGANJ3RZAAIVYGQ';
      myFirstname = 'firstName_test1001';
      myLastname = 'firstName_test1001';
      break;
    case '2':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTZmZmI1NjAzYjlhZjcwMDM4NjIiLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.UAobV3ykLBVMTSBzv1YqyN-2Vp8x9BGRYifesyyqMMU';
      myId = '656f16ffb5603b9af7003862';
      myAppId =
        'WYO5JEC5KTDLOAQYIOYPF6BYWSQMXX6WCKYP2RKVBHYN4VPZQEAWITZGN4SEYD5FYE5XNK2JIWHADC3T3NQQYFWRCUC3F6KIFCGONIQ';
      myFirstname = 'firstName_test1002';
      myLastname = 'firstName_test1002';
      break;
    case '3':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTcwZjYwMGI2ODdjZGYwZGU5MjAiLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.D9yvoXcE4EN8lmP58cyOkUF2Hc93QH3mlQWgJgaa1Hc';
      myId = '656f170f600b687cdf0de920';
      myAppId =
        '4KKWJMOBYJCPPZU63E7YK46YIXQWOVGRZ4P2CNROBTDTXGWBEXVC5IKJHX5I4BUWYTCCAVUGKOPPJ55QDDHZAYV3RH7NNRB6YTSBT3Q';
      myFirstname = 'firstName_test1003';
      myLastname = 'firstName_test1003';
      break;
    case '4':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTcxYTRlNzMyMWY2NTgwMTdjMTMiLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.BGT4kiUTBLv5Ukol5Q4AFopQK89YHaScAcN8I6pgwxs';
      myId = '656f171a4e7321f658017c13';
      myAppId =
        'JCX4XEA76552IHSWOISTA45JLPIKQX2BX3ZPFF7TK6IBKEG5NQQEKO5BV6R4EE2UWVAP2PH3ZSZI6QKCMLYEJ4RWFFRSBDD433ZUIRA';
      myFirstname = 'firstName_test1004';
      myLastname = 'firstName_test1004';
      break;
    case '5':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTcyOTRlNzMyMWY2NTgwMTdjMTQiLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.IoajWdRZEjgyWXCJk2_o_yaXyYC1lSoiD-PdUsGHIuo';
      myId = '656f17294e7321f658017c14';
      myAppId =
        'MVH7FQINJD5OP4CJB6TVSU36T4T7WQTHWOY4CRNPY4CNCSRLXMV7XW7344V6KWTLQPRUP6FID4L3AGWDL6IOOZOQ3H77JLHZVMJ7KRI';
      myFirstname = 'firstName_test1005';
      myLastname = 'firstName_test1005';
      break;
    case '6':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTc4MGI1NjAzYjlhZjcwMDM4NjQiLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.VCsL5ijbTb6W8loUZk27P-TcI0SX6nx6VMm_ZSjkhJY';
      myId = '656f1780b5603b9af7003864';
      myAppId =
        'AIFR4MFN5G5VOHOZQVWZ325BVIXMKGSNCJ44D2KZBBD4AY5D464HPQ4CH7MXFWJWSHCTXTFHK3QRJ3DIACVOABMPGRN5VGZDFPVAQSQ';
      myFirstname = 'firstName_test1006';
      myLastname = 'firstName_test1006';
      break;
    case '7':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTc5NDRlNzMyMWY2NTgwMTdjMTYiLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.i_oo9Az1EPUur6LSikwWmRT2xUTtfo9SmqGuVVofnHY';
      myId = '656f17944e7321f658017c16';
      myAppId =
        'N7GW5I2QDASLTIUQVJF6BBUOG2CCNTWXG6H74LQ3MIBZBIBBOIXLJARISRDV6BOIX7EARU2FZ5CKA7NX6EVVQV5KKLAK5BZQFGLPFAA';
      myFirstname = 'firstName_test1007';
      myLastname = 'firstName_test1007';
      break;
    case '8':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTdhNTYwMGI2ODdjZGYwZGU5MjEiLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.o1FKKSjMelc_P_S-izSZjktGZV7vy5pWcw6oJvMSj0M';
      myId = '656f17a5600b687cdf0de921';
      myAppId =
        'MHVNUKGDJ625UYXDIOUSTKA72CTOGTQNYCIVL5TCNDN5RPFLDKESUMBLP6L4OXZFC4DSJXDZGQHQT2OEWD2U2KHDVIUSYTGARZL7MUA';
      myFirstname = 'firstName_test1008';
      myLastname = 'firstName_test1008';
      break;
    case '9':
      myJWT =
        'eyJraWQiOjIsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NTZmMTdiOTRlNzMyMWY2NTgwMTdjMTciLCJkZXYiOiI1NGQxMmMxYS0wMDAxLTExZWQtODQ2ZC1jNzM5YmE5MGQ5YzkifQ.Lwh4K6YaxZ_sR-c8ApANZoYecZOihzjTMuWJzvh-1Nk';
      myId = '656f17b94e7321f658017c17';
      myAppId =
        'IDIK3HAFE6KEDWIXCJ2YDLON6GT4JWD66ITBDXJXQ7BJS7KKJKRXS2G4V3J3ULPY4SRNEKS6VBE7NVTKKR5RM35I7UCGE3J5DGGWVGY';
      myFirstname = 'firstName_test1009';
      myLastname = 'firstName_test1009';
      break;
    case '10':
      // local-dev
      myJWT =
        'eyJhbGciOiJIUzI1NiIsImtpZCI6MCwidHlwIjoiSldUIn0.eyJhcHAiOiJFNzkwRDEwNi0wQzA1LTQyNjMtODgyRC1FNUQ2NjVDRjUzQzEiLCJzdWIiOiI2NTRiOTc3NGI0YmQ1NzcwMzE2NmFiNGMifQ.9hhPewdGxf_8xG8CH-jm0tuAHxFSYr3sSrEZkz8BG-U';
      myId = '654b9774b4bd57703166ab4c';
      myAppId = 'foo';
      myFirstname = 'Karl';
      myLastname = 'Steinscheisser';
      myImage = {
        filename: '5bb0b4b6-1bcc-4dac-b8bf-8031f40ab1da.jpg',
        url: 'https://gds-control-api.bretterklieber.com/media/5bb0b4b6-1bcc-4dac-b8bf-8031f40ab1da.jpg',
        _id: '64be54932542d97ece067554',
      };
      break;
    case '11':
      // tst
      myJWT =
        'eyJhbGciOiJIUzI1NiIsImtpZCI6MCwidHlwIjoiSldUIn0.eyJhcHAiOiJFNzkwRDEwNi0wQzA1LTQyNjMtODgyRC1FNUQ2NjVDRjUzQzEiLCJzdWIiOiI2M2ViOTc3NjZjOWVjNDk1ZTAwYjEwOTQifQ.x-7C1E-UU0Uxrye9EoVH0SsGKMZtZ9qGQBNpJfQponw';
      myId = '63eb97766c9ec495e00b1094';
      myAppId = 'foo';
      myFirstname = 'Karl';
      myLastname = 'Steinscheisser';
      myImage = {
        filename: '5bb0b4b6-1bcc-4dac-b8bf-8031f40ab1da.jpg',
        url: 'https://gds-control-api.bretterklieber.com/media/5bb0b4b6-1bcc-4dac-b8bf-8031f40ab1da.jpg',
        _id: '64be54932542d97ece067554',
      };
      break;
    case '12':
      // tst
      myJWT =
        'eyJraWQiOjAsImFsZyI6IkhTMjU2IiwidHlwIjoiSldUIn0.eyJzdWIiOiI2NmU5MmMyYjA2ODUwNDMzOTM0OWEwMWYiLCJhcHAiOiIzOTE4ZWU4ZS1kZDEyLTQxNjYtOTkzNS1hMGQ1NjEyODQ3NWQifQ.5R9gLhaHHxkFsmOdH7vrSOK3z0pl7VJZoPNk4LbbdTU';
      myId = '66e92c2b068504339349a01f';
      myAppId = '3918ee8e-dd12-4166-9935-a0d56128475d';
      myFirstname = 'Christian';
      myLastname = 'Stangl';
      myImage = {
        filename: '45bacd1b-516e-4769-8d97-4fd4f24d5961.jpeg',
        url: 'https://control-api-tst.geomix-soccerboard.com/media/45bacd1b-516e-4769-8d97-4fd4f24d5961.jpeg',
        _id: '66e92e298717fd2149b00cf2',
      };
      break;
    case '13':
      myJWT =
        'eyJhbGciOiJIUzI1NiIsImtpZCI6MCwidHlwIjoiSldUIn0.eyJhcHAiOiJPTE1VVk4zWkc0WDM0QVhZVTZHTkNQMlVNWDZKTlJXT1ZPSFZDV1AzNFlXMzZFSUtIRVFWVUpJNFFESks3RUlTTExQSktHTUNJRlNCNUhRWkpQWTdKUkpEWEhDTVo2VTZRTVFLN1hRIiwic3ViIjoiNjQ4YzMzM2E3ZWVhYmMwZjg3MGI4YWEyIn0.TG0qyv11zCWXuMBXFE1FHT-dUYpGA4fWnkeMmtYW8C0';
      myId = '648c333a7eeabc0f870b8aa2';
      myAppId =
        '6OC2NKXGYCAEA7TDIBWEWMI4W7ZJWZR2C4KNZRF2YVVGYX7G5XXT6HN65XVM22GF624BNSVAESYRELY735U6RLWJDGANJ3RZAAIVYGQ';
      myFirstname = 'Friedrich';
      myLastname = 'Endlicher';
      modules = MODULE_OPTIONS_PREMIUM;

      break;
    case '14':
      myJWT =
        'eyJhbGciOiJIUzI1NiIsImtpZCI6MCwidHlwIjoiSldUIn0.eyJhcHAiOiJPTE1VVk4zWkc0WDM0QVhZVTZHTkNQMlVNWDZKTlJXT1ZPSFZDV1AzNFlXMzZFSUtIRVFWVUpJNFFESks3RUlTTExQSktHTUNJRlNCNUhRWkpQWTdKUkpEWEhDTVo2VTZRTVFLN1hRIiwic3ViIjoiNjQ5MTYzOWJkODQ0ZGEzYzM2MGQ4NTAyIn0.q32QtvhjWY7sd-38L7JWVC5Nx_v6fSA1g4Hgtks7H0c';
      myId = '6491639bd844da3c360d8502';
      myAppId =
        'OLMUVN3ZG4X34AXYU6GNCP2UMX6JNRWOVOHVCWP34YW36EIKHEQVUJI4QDJK7EISLLPJKGMCIFSB5HQZJPY7JRJDXHCMZ6U6QMQK7XQ';
      myFirstname = 'Testinvite';
      myLastname = 'Friedrich';
      modules = MODULE_OPTIONS_BASIC;
      break;
    default:
      break;
  }

  return {
    id: myId,
    appId: myAppId,
    jwt: myJWT,
    firstname: myFirstname,
    lastname: myLastname,
    image: myImage,
    roles: [],
    modules: modules,
  };
}
