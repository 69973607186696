import React, {useEffect, useMemo, useState} from 'react';

import {Add as AddIcon, PersonAdd as PersonAddIcon} from '@mui/icons-material';
import {
    Fab,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';

import {
    ConfirmDialog,
    Text, useConfirmDialog
} from 'components';
import {useTranslation} from 'hooks';
import {IconButton} from 'module/client/index';
import {MenuItem} from 'module/client/index';
import {positions} from 'module/client/team/useTeamApi';
import {useLicenseManager} from 'module/user';
import {useUserUtils} from 'module/user/useUser';
import {useLayoutStore, useSnackbarStore} from 'store';

import Create from './Create';
import Edit from './Edit';
import Invite from './Invite';
import {useInfo} from './useInfo';
import {groupByPosition, useApiDelete, useApiQuery} from './useMemberApi';

const styles = {
    container: {
        padingY: 2.5,
        paddingBottom: 2.5,
    },
    actionButtonContainer: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'grey.darkest2',
        paddingY: 2.5,
        cursor: 'pointer',

        '&:last-child': {
            borderBottom: 0,
        }
    },
    actionButton: {
        flexShrink: 0,
        backgroundColor: 'red.highlight',
        marginRight: 2.5,
        ':hover': {
            backgroundColor: 'red.dark',
        },
    },
    listHolder: {
        padding: 0,
        marginBottom: 2.5,
    },
    listHolderLast: {
        paddingBottom: 5,
    },
    listItem: {
        width: '100%',
    },
    listItemTitle: {
        backgroundColor: 'grey.darkest2',
        paddingY: 0.5,
        paddingX: 2.5,
    },
};

const Member = ({member, onClick, onToggleMenu, showMenu, onDelete, onEdit, isManager}) => {
    const memberInfo = useInfo();
    const {firstname, lastname, initials, trainer} = memberInfo(member);

    const doClick = () => {
        if (!member.user) {
            return;
        }

        onClick(member);
    };

    const collapseButtons = [];

    if (isManager) {
        collapseButtons.push(<IconButton
            key="pencil"
            icon="pencil"
            onClick={() => onEdit(member)}
        />);
        collapseButtons.push(<IconButton
            key="delete"
            icon="delete"
            color="red.dark"
            onClick={() => onDelete(member)}
        />);
    }

    return <MenuItem
        onClick={doClick}
        avatarText={initials}
        image={member.user?.image}
        listItemTextPrimary={<Typography>{firstname} {lastname} {trainer}</Typography>}
        showBadge={member?.user && true}
        moreText={member?.number}
        pullerFunction={(e) => onToggleMenu(e, member)}
        collapseButtons={collapseButtons}
        showMenu={showMenu}
    />;
};

const Members = ({team}) => {
    const translate = useTranslation();
    const {hasAccess} = useLicenseManager();

    const [selection, setSelection] = useState(null);
    const [showEdit, setShowEdit] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [showInvite, setShowInvite] = useState(false);

    const {isOwner} = useUserUtils();
    const isManager = isOwner(team.managers);

    const setShowLoading = useLayoutStore((state) => state.setShowLoading);
    const setShowMember = useLayoutStore(state => state.setShowMember);

    const showInfo = useSnackbarStore((state) => state.show);

    const {del, loading} = useApiDelete();

    const query = useApiQuery({teamId: team.id});

    const members = useMemo(() => {
        return groupByPosition(query.data, true);
    }, [query.data]);

    const handleEdit = (_id) => {
        setShowEdit(true);
    };

    const handleDelete = (member) => {
        deleteConfirm.show(member);
    };

    const deleteConfirm = useConfirmDialog(
        async (member) => {
            try {
                del(team.id, member.id);
                query.refetch();
                showInfo(translate('client.action.member.delete_success'));
            } catch (e) {
                showInfo(e.message, {severity: 'warning'});
            }
        },
        undefined,
        translate('client.action.member.confirm_delete')
    );

    const handleSelect = (member) => {
        setShowMember(true, member);
    };

    const onSave = async (newTeam) => {
        setShowCreate(false);
        await query.refetch();
        setSelection(newTeam);
    };

    const toggleMenu = (e, member) => {
        if (e.dir === 'Left') {
            setSelection(member);
        } else if (e.dir === 'Right') {
            setSelection(null);
        } else {
            setSelection(selection === member ? null : member);
        }
    };

    useEffect(() => {
        setShowLoading(query.isFetching || loading);
        return () => {
            setShowLoading(false);
        };
    }, [query.isFetching, setShowLoading, loading]);

    // const iconStyle = {
    //     ...styles.absolutIcon,
    //     fill: theme.palette.white.main
    // };

    // TODO add limit
    const memberCount = positions.map((position) => members[position].length).reduce((a, b) => a + b, 0);
    console.log('memberCount', memberCount);
    return (
        <>
            <ConfirmDialog {...deleteConfirm} />
            {showEdit && <Edit team={team} member={selection} onClose={() => setShowEdit(false)} onSave={onSave}/>}
            {showCreate && <Create team={team} onClose={() => setShowCreate(false)} onSave={onSave}/>}
            {showInvite && <Invite team={team} onClose={() => setShowInvite(false)} onSave={onSave}/>}

            <Stack sx={styles.container}>

                {isManager && hasAccess('team-members')?.limit?.max > memberCount && <Stack mb="16px" sx={styles.listItem}>
                    <Stack
                        sx={styles.actionButtonContainer} direction="row" alignItems="center"
                        onClick={() => setShowCreate(true)}>
                        <Fab disableRipple sx={styles.actionButton}><AddIcon color="white"/></Fab>
                        <Typography><Text>client.action.member.add</Text></Typography>
                    </Stack>
                    <Stack
                        sx={styles.actionButtonContainer} direction="row" alignItems="center"
                        onClick={() => setShowInvite(true)}>
                        <Fab disableRipple sx={styles.actionButton}><PersonAddIcon color="white"/></Fab>
                        <Typography><Text>client.action.member.invite</Text></Typography>
                    </Stack>
                </Stack>}
                {/* // TODO styling / translation  */}
                {isManager &&hasAccess('team-members')?.limit?.max <= memberCount && <Stack
                    sx={styles.actionButtonContainer} onClick={() => console.log('LINK TO PREMIUM PAGE')} direction="row" alignItems="center">
                    <Fab disableRipple sx={styles.actionButton}><AddIcon color="white"/></Fab>
                    <Typography><Text>{/* unTranslated */}Mehr Spieler mit Premium!</Text></Typography>
                </Stack>}
                {/*<Stack sx={[styles.actionButtonContainer, styles.actionButtonNormalBg]} direction="row" alignItems="center">*/}
                {/*    <Stack width={56} alignItems="center" mr={2}>*/}
                {/*        <Icons.Startelf width={30} height={30} ml={2} style={iconStyle}/>*/}
                {/*    </Stack>*/}
                {/*    <Stack flexGrow="1" color="white.main"><Text>Startelf festlegen</Text></Stack>*/}
                {/*    <Icons.Pencil width={25} height={25} style={iconStyle}/>*/}
                {/*</Stack>*/}

                {positions.map(position => <List key={position} sx={styles.listHolder}>
                    <ListItem>
                        <ListItemText sx={styles.listItemTitle}><Typography>{translate(`team.position.${position}`)}</Typography></ListItemText>
                    </ListItem>
                    {members[position].map(member => <Member
                        key={member.id}
                        member={member}
                        isManager={isManager}
                        onToggleMenu={toggleMenu}
                        onDelete={handleDelete}
                        onEdit={handleEdit}
                        onClick={handleSelect}
                        showMenu={selection?.id === member.id}/>)}

                    {members[position].length < 1 && <Typography color="grey.lightest">Keine
                        Mitglieder</Typography>}

                </List>)}

                <List sx={[styles.listHolder, styles.listHolderLast]}>
                    <ListItem>
                        <ListItemText sx={styles.listItemTitle}><Typography><Text>client.officials</Text></Typography></ListItemText>
                    </ListItem>
                    {members.officials.map(member => <Member
                        key={member.id}
                        member={member}
                        isManager={isManager}
                        onToggleMenu={toggleMenu}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        onClick={handleSelect}
                        showMenu={selection?.id === member.id}/>)}

                    {members.officials.length < 1 && <Typography color="grey.lightest">Keine
                        Mitglieder</Typography>}
                </List>

            </Stack>

        </>
    );
};

export default Members;