import React from 'react';

import {
    Button,
    DialogActions,
    DialogContent,
    Typography,
} from '@mui/material';

import {Dialog as BaseDialog, Text} from 'components';
import {useTranslation} from 'hooks';
import {Modes as modes} from 'lib/tactix';
import {useInfoStore, useLayoutStore} from 'store';

import {toggleAppLogin} from './api';

const Dialog = ({open, handleClose}) => {
    const translate = useTranslation();
    const setShowLogin = useLayoutStore((state) => state.setShowLogin);
    const setShowPairing = useLayoutStore((state) => state.setShowPairing);
    const mode = useInfoStore(state => state.mode);
    // 1. mode app opens login/register dialog in app
    // 2. mode browser displays login/register information

    const handleLogin = (route) => {
        // show the login/register dialog in app
        toggleAppLogin(route);
        // hide the login/register dialog in board
        setShowLogin(false);
    };

    const handlePairing = () => {
        setShowPairing(true);
        setShowLogin(false);
    };
 
    return <BaseDialog open={open} handleClose={handleClose} title={translate('rest.global.unauthorized')}>
        {mode === modes.APP ?
            ( <DialogContent>
                <Typography variant="body1"><Text>{/* unTranslated */}Um diese Funktionalität zu verwenden, musst Du dich mit deinem Tactix-Benutzer anmelden oder registrieren.</Text></Typography>
            </DialogContent>)
            : <DialogContent><Typography variant="body1"><Text>{/* unTranslated */}Um diese Funktionalität zu verwenden, musst Du dich mit der Tactix App pairen.</Text></Typography></DialogContent>}
        {mode === modes.APP ?
            <DialogActions>
                <Button variant="contained" onClick={() => handleLogin('login')}>
                    <Text>{/* unTranslated */} Zum Login</Text>
                </Button>
                <Button variant="text" onClick={() => handleLogin('register')}>
                    <Text>{/* unTranslated */} Jetzt Benutzer erstellen</Text>
                </Button>
            </DialogActions> :
            <DialogActions>  <Button variant="contained" onClick={() => handlePairing()}>
                <Text>Pairing starten</Text>
            </Button> </DialogActions>}

    </BaseDialog>;
};

export default Dialog;
