import React from 'react';
import {createRoot} from 'react-dom/client';

import App from './App';

// disable contextmenu

if (import.meta.env.DEV === false) {
    console.log('DEVMODE', import.meta.env.DEV);
    document.addEventListener('contextmenu', function (e) {
        e.preventDefault();
    });

    document.addEventListener('selectstart', function (e) {
        e.preventDefault();
    });
}

// https://gitea.geodev.at/geomix/tactix-board/issues/645
window.addEventListener('vite:preloadError', (_event) => {
    console.log('Vite pre-loading failed. Reloading page...');
    window.location.reload(); // for example, refresh the page
});

// hide loading info, displayed by index.html
const loadingInfo = document.getElementById('loadingInfo');
loadingInfo.parentNode.removeChild(loadingInfo);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App/>);
