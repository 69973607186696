import React, {useEffect, useState} from 'react';

import {Button, Stack, Tab, Tabs, Typography} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';

import {useApiQuery as useTeamApiQuery} from 'module/client/team/useTeamApi';
import {useApiQuery as useClientApiQuery} from 'module/client/useClientApi';
import {useSettingsStore} from 'module/settings/zustand';

const styles = {
    scrollContainer: {
        marginBottom: 2,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: '0', // for firefox
        width: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            height: '100%',
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'grey.light',
            borderRadius: 2
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'grey.lightest',
            borderRadius: 2
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'grey.lightest',
            borderRadius: 2
        },
    },
    listContainer: {
        width: '100%',
    },
    clientNameGroupTitle: {
        background: 'linear-gradient(to bottom, #1c1c1c, #111)',
        padding: 2,

    },
    clientNameGroup: {
        padding: '0 10px 10px 10px',
        width: '100%',
        overflow: 'hidden !important',
    },
    clientName: {
        width: '100%',
        fontSize: '16px',
        color: 'white.main',
        alignItems: 'flex-start',
        padding: 2,
        borderTop: '1px solid',
        borderTopColor: 'grey.darkest2',
    },
    teamNameGroupTitle: {
        background: 'linear-gradient(to bottom, #1c1c1c, #111)',
        padding: 1,
        paddingTop: 2,
    },
    teamNameGroup: {
        width: '100%',
        boxSizing: 'border-box',
        overflow: 'hidden !important',
        padding: 1,
    },
    teamName: {
        width: '100%',
        fontSize: '16px',
        color: 'white.main',
        alignItems: 'flex-start',
        padding: '15px',
        boxSizing: 'border-box',
        borderTop: '1px solid',
        borderTopColor: 'grey.darkest2',
        overflow: 'hidden !important',
        justifyContent: 'flex-start',
    },
};

const Selector = () => {
    const [client, setClient] = useState(null);

    const setSelectedTeam = useSettingsStore(state => state.setSelectedTeam);

    const queryClient = useQueryClient();

    const clientQuery = useClientApiQuery();
    const teamQuery = useTeamApiQuery({clientId: client});

    const selectTeam = (team) => {
        queryClient.invalidateQueries({queryKey: ['user-team', team.id]});
        setSelectedTeam(team.id);
    };

    useEffect(() => {
        if (client === null && clientQuery.data?.length > 0) {
            setClient(clientQuery.data[0].id);
        }
    }, [client, clientQuery.data]);

    return <Stack direction="column" sx={styles.scrollContainer}>
        <Stack sx={styles.clientNameGroupTitle}>
            <Typography align="center">{/* unTranslated */}Bitte wähle den Verein aus:</Typography>
        </Stack>
        <Stack direction="column" sx={styles.listContainer}>
            <Tabs
                value={client}
                onChange={(_e, v) => setClient(v)}
                variant="scrollable"
                orientation="vertical"
                sx={styles.clientNameGroup}>
                {clientQuery.data?.map(c => <Tab key={c.id} label={c.name} value={c.id} sx={styles.clientName} />)}
            </Tabs>
        </Stack>

        {client && teamQuery.data?.length > 0 && <>
            <Stack direction="column" sx={styles.listContainer}>
                <Stack sx={styles.teamNameGroupTitle}>
                    <Typography textAlign="center">{/* unTranslated */}Bitte wähle das Team aus:</Typography>
                </Stack>
                <Stack sx={styles.teamNameGroup}>
                    {teamQuery.data.map(t => <Button
                        key={t.id} onClick={() => selectTeam(t)}
                        sx={styles.teamName}>{t.name}</Button>)}
                </Stack>
            </Stack>
        </>}
    </Stack>;
};

export default Selector;